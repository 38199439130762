import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';
import { EmasDigiService } from '../../core/services/emas-digi.service';

@Component({
  selector: 'app-agen-harga-emas',
  templateUrl: './agen-harga-emas.component.html',
  styleUrls: ['./agen-harga-emas.component.scss']
})
export class AgenHargaEmasComponent implements OnInit, OnDestroy {

  private _destroyed$ = new Subject();

  buyPrice: any;
  sellPrice: any;
  updatedAt: any;

  constructor(private spinner: NgxSpinnerService,
              private emasDigiService: EmasDigiService) { }

  ngOnInit() {
    this.spinner.show();
    this.emasDigiService.getCurrentGoldPrices()
        .pipe(takeUntil(this._destroyed$))
        .subscribe(
            data => {
                this.buyPrice = data['data']['current_price']['sell'];
                this.sellPrice = data['data']['current_price']['buy'];
                this.updatedAt = data['data']['current_price']['updated_at'];
                this.spinner.hide();
            },
            err => {
                this.spinner.hide();
            }
        );
  }
  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { Config } from '../config';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient,
              private config: Config) { }

  getMedias() {
    return this.http
      .post<any>(this.config.url + 'medias', {
          limit: 35
      })
      .pipe(map(data => data));
  }

  getRandomPartners(limit: number) {
    return this.http
      .post<any>(this.config.url + 'search/travels', {
        limit: limit,
        sortBy: 'random'
       })
       .pipe(map(data => data));
  }

  getBlogs(limit: number) {
      return this.http
        .post<any>(this.config.url + 'articles', {
           limit: limit
        })
        .pipe(map(data => data));
  }

  getBanners() {
      return this.http
        .post<any>(this.config.url + 'banners', {
           limit: 10
        })
        .pipe(map(data => data));
  }

  getTestimonials(type) {
    return this.http
      .get<any>(this.config.url + 'testimonials?type='+ type)
      .pipe(map(data => data));
  }
}

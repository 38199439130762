import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';
import { map } from 'rxjs/operators';
import { Config } from '../config';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(@Inject(LOCAL_STORAGE) private localStorage: any,
              private http: HttpClient,
              private config: Config,
              private router: Router) { }

  getSetting(key) {
    return this.http
      .get<any>(this.config.url + 'setting/' + key)
      .pipe(map(data => data));
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import localeId from '@angular/common/locales/id';
import { registerLocaleData , CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';


import { SharedModule } from './shared/shared.module';
import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { PageComponent } from './page/page.component';
import { NotFoundComponent } from './not-found/not-found.component';

// import { AppComponent } from './app.component';
// import { CommonModule } from '@angular/common';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { NgtUniversalModule } from '@ng-toolkit/universal';
// import { HeaderComponent } from './layout/header/header.component';
// import { FooterComponent } from './layout/footer/footer.component';

import { HttpErrorInterceptor } from './core/http-error.interceptor';
import { AgenKudoComponent } from './agen-kudo/agen-kudo.component';
import { AgenTopupComponent } from './agen-kudo/agen-topup/agen-topup.component';
import { AgenHargaEmasComponent } from './agen-kudo/agen-harga-emas/agen-harga-emas.component';
import { AgenTopupDetailComponent } from './agen-kudo/agen-topup-detail/agen-topup-detail.component';
import { AgenDashboardComponent } from './agen-kudo/agen-dashboard/agen-dashboard.component';
import { AgenDaftarKilauUmrohComponent } from './agen-kudo/agen-daftar-kilau-umroh/agen-daftar-kilau-umroh.component';
import { AgenPaymentSuccessComponent } from './agen-kudo/agen-payment-success/agen-payment-success.component';
import { AgenPaymentConfirmationComponent } from './agen-kudo/agen-payment-confirmation/agen-payment-confirmation.component';
import { AgenUmrohPackageComponent } from './agen-kudo/agen-umroh-package/agen-umroh-package.component';
import { AgenHistoryComponent } from './agen-kudo/agen-history/agen-history.component';
import { AgenRegisterComponent } from './agen-kudo/agen-register/agen-register.component';
import { AgenFaqComponent } from './agen-kudo/agen-faq/agen-faq.component';

registerLocaleData(localeId, 'id');

import * as jQuery from 'jquery';
import { VaksinComponent } from './vaksin/vaksin.component';
import { PackageAgentComponent } from './package-agent/package-agent.component';
import { LandingComponent } from './landing/landing.component';
import { PergiBelanjaComponent } from './pergi-belanja/pergi-belanja.component';
import { MediaComponent } from './media/media.component';
import { QurbanComponent } from './qurban/qurban.component';
import { PaymentComponent } from './qurban/payment/payment.component';
import { Myim3Component } from './pergi-belanja/myim3/myim3.component';
import { DetailComponent } from './pergi-belanja/detail/detail.component';
import { UmrohMandiriComponent } from './umroh-mandiri/umroh-mandiri.component';
import { PackageComponent } from './umroh-mandiri/package/package.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    PageComponent,
    NotFoundComponent,
    AgenKudoComponent,
    AgenTopupComponent,
    AgenHargaEmasComponent,
    AgenTopupDetailComponent,
    AgenDashboardComponent,
    AgenDaftarKilauUmrohComponent,
    AgenPaymentSuccessComponent,
    AgenPaymentConfirmationComponent,
    AgenUmrohPackageComponent,
    AgenHistoryComponent,
    AgenRegisterComponent,
    AgenFaqComponent,
    VaksinComponent,
    PackageAgentComponent,
    LandingComponent,
    PergiBelanjaComponent,
    MediaComponent,
    QurbanComponent,
    PaymentComponent,
    Myim3Component,
    DetailComponent,
    UmrohMandiriComponent,
    PackageComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'pergiumroh-frontend'}),
    AppRoutingModule,
    CommonModule,
    TransferHttpCacheModule,
    HttpClientModule,
    NgtUniversalModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    InfiniteScrollModule
  ],
  providers: [
    {
       provide: HTTP_INTERCEPTORS,
       useClass: HttpErrorInterceptor,
       multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

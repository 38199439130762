import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class Config {
  url = environment.apiUrl;
  emasDigiUrl = environment.emasDigiUrl;
  kudoUrl = environment.kudoUrl;
  treasuryUrl = environment.emasDigiUrl;
  constructor() { }
}

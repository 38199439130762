import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

import { Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';

import { KilauUmrohService } from '../../core/services/kilau-umroh.service';

@Component({
  selector: 'app-agen-faq',
  templateUrl: './agen-faq.component.html',
  styleUrls: ['./agen-faq.component.scss']
})
export class AgenFaqComponent implements OnInit, OnDestroy {

  private _destroyed$ = new Subject();

  title: any;
  termsAndConditions: any;

  constructor(private location: Location,
              private sanitizer: DomSanitizer,
              private spinner: NgxSpinnerService,
              private kilauUmrohService: KilauUmrohService) { }

  ngOnInit() {
    this.spinner.show();
    this.kilauUmrohService.getTnCKudo()
    .pipe(
      takeUntil(this._destroyed$))
    .subscribe(
      data => {
        this.title = data['data']['title'];
        this.termsAndConditions = this.sanitizer.bypassSecurityTrustHtml(data['data']['content']);
        this.spinner.hide();
      },
      err => {  this.spinner.hide(); }
    );

  }

  onBack() {
    this.location.back();
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

}

import { Component, OnInit, Injectable, Inject } from '@angular/core';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { LOCAL_STORAGE } from '@ng-toolkit/universal';

import { NgxSpinnerService } from 'ngx-spinner';
import { CoreAuthService } from '../core/services/auth.service';
import { KudoService } from '../core/services/kudo.service';

@Component({
  selector: 'app-agen-kudo',
  templateUrl: './agen-kudo.component.html',
  styleUrls: ['./agen-kudo.component.scss']
})
export class AgenKudoComponent implements OnInit {

  username: string = this.localStorage.getItem('name') ? this.localStorage.getItem('name') : '-';

  loginForm = {
    emailOrPhone: '',
    password: ''
  };

  loginErrorStatus: boolean = false;
  loginErrorText: string = "Nomor handphone atau password yang Anda masukkan salah.";

  constructor(
              @Inject(LOCAL_STORAGE) private localStorage: any,
              private router: Router,
              private spinner: NgxSpinnerService,
              public authService: CoreAuthService,
              public kudoService: KudoService) { }

  ngOnInit() {
    if (this.authService.isAuthenticatedCheckAgent['value']) {
      this.router.navigate(['/kudo/dashboard']);
    } else {
      // alert('gak login');
    }
  }

  onLogin() {

    this.spinner.show();
    this.kudoService.login(this.loginForm.emailOrPhone, this.loginForm.password)
        .subscribe(
            data => {
                const isVerification = data['data']['status'];
                if (isVerification === "0") {
                    this.loginErrorStatus = true;
                    this.loginErrorText = "Akun belum aktif, silahkan verifikasi akun terlebih dahulu.";
                    this.loginForm.password = "";
                } else {
                    this.localStorage.setItem('token', data['access_token']);
                    this.localStorage.setItem('name', data['data']['name']);
                    this.localStorage.setItem('isAgent', data['data']['is_agent']);
                    this.loginForm.emailOrPhone = "";
                    this.loginForm.password = "";
                    this.loginErrorStatus = false;
                    this.router.navigate(['/kudo/dashboard']);

                    this.authService.checkAuthenticatedStatus(true);
                    this.authService.checkAgentStatus(true);

                    this.authService.username.next(data['data']['name']);

                }
                this.spinner.hide();
            },
            err => {
                this.loginErrorStatus = true;
                this.loginErrorText = "Nomor handphone atau password yang Anda masukkan salah.";
                this.loginForm.password = "";
                this.spinner.hide();
            }
        );
  }
}

import { Component, OnInit } from '@angular/core';

import { NgxSpinnerService} from 'ngx-spinner';
import { HomeService } from '../core/services/home.service';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit {

	medias: Array<any>;

  constructor(private homeService: HomeService, private spinner: NgxSpinnerService,) { }

  ngOnInit() {
  	this.homeService.getMedias().subscribe(
      data => {
      	console.log(data)
        this.medias = data['data'];
        for (let i = 0; i < this.medias.length; i++) {
          this.medias[i]['show'] = false;
        }
        this.spinner.hide();
      },
      err => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }

}

import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

declare let ga: Function;

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(
      private title: Title,
      private meta: Meta,
      private router: Router,
      private spinner: NgxSpinnerService) {
      title.setTitle('Halaman tidak ditemukan | Pergiumroh');
      meta.updateTag({name: 'robots', content: 'noindex'});
  }

  ngOnInit() {
    ga('send', 'event', {
      eventCategory: '404 error',
      eventLabel: this.router.url
    });
  }

}

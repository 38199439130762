import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Meta, Title } from '@angular/platform-browser';

import { Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';

import { PromoService } from '../core/services/promo.service';

declare let ga: Function;

@Component({
  selector: 'app-package-agent',
  templateUrl: './package-agent.component.html',
  styleUrls: ['./package-agent.component.scss']
})
export class PackageAgentComponent implements OnInit, OnDestroy {

   private _destroyed$ = new Subject();

   promo: any;

   search = {
     sortby: 'departureDate',
   };

  constructor(private meta: Meta,
              private title: Title,
              private router: Router,
              private route: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private promoService: PromoService) { }

  ngOnInit() {

    const slug = 'daftar-umroh';
    this.spinner.show();
    this.promoService.getPromoBySlugAgent(slug)
    .pipe(takeUntil(this._destroyed$))
    .subscribe(
       data => {

         ga('send', 'event', {
          eventCategory: 'Promo',
          eventAction: 'Promo Reseller Viewed',
          eventLabel: data['data']['name']
        });

        // META
        // console.log(data);
        // this.title.setTitle(data['data']['seo_title']);
        // this.meta.updateTag({ name: 'description', content: data['data']['seo_metadesc'] });
        // this.meta.updateTag({ name: 'keywords', content: data['data']['seo_metakeyword'] });
        // this.meta.updateTag({ property: 'og:title', content: data['data']['seo_title'] });
        // this.meta.updateTag({ property: 'og:description', content: data['data']['seo_metadesc'] });
        // this.meta.updateTag({ property: 'og:image', content: data['data']['banner'] });
        // this.meta.updateTag({ property: 'twitter:title', content: data['data']['seo_title'] });
        // this.meta.updateTag({ property: 'twitter:description', content: data['data']['seo_metadesc'] });
        // CORE
        this.promo = data['data'];
        this.spinner.hide();
      },
      err => { 
        this.spinner.hide(); 
      }
    )
  }

  onSortBy(value) {
      const slug = this.route.snapshot.params['slug'];
      this.spinner.show();
      this.promoService.getPromoBySlugAgent(slug, value)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(
         data => {
          this.promo = data['data'];
          this.spinner.hide();
        },
        err => { 
          this.spinner.hide(); 
        }
      )
   }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

}

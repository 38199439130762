import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { Config } from '../config';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  constructor(private http: HttpClient,
              private config: Config) { }

  getPartners(page: string, limit) {
    return this.http
      .post < any[] > (this.config.url + 'search/travels' + page, {
        limit: limit,
        sortBy: 'asc'
      })
      .pipe(map(data => data));
  }

  getPartnerBySlug(slug: string) {
    return this.http
      .get<any[]>(this.config.url + 'travel/' + slug)
      .pipe(map(data => data));
  }

  getRandomPartners(limit: number) {
    return this.http
      .post<any>(this.config.url + 'search/travels', {
          limit: limit,
          sortBy: 'random'
      })
      .pipe(map(data => data));
  }

}

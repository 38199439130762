import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';


@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private meta: Meta) { }

  generateTags(config) {
    // default values
    config = {
      title: 'Pergiumroh',
      description: 'Cari dan dapatkan produk umroh dari berbagai travel umroh berizin. ' +
      'Cara baru berumroh dengan aman dan nyaman serta dapatkan Paket Umroh yang terbaik dari pergi umroh',
      keywords: 'Pergiumroh, Paket Umroh 2019, umroh murah, umroh promo, pergi umroh',
      image: 'https://storage.googleapis.com/pastiumroh/assets/logo-pu-transparent.png',
      url: '',
      slug: '',
      ...config
    };

    // UPDATE META TAGS
    this.meta.updateTag({ name: 'description', content: config.description });
    this.meta.updateTag({ name: 'keywords', content: config.keywords });
    this.meta.updateTag({ name: 'robots', content: 'INDEX, FOLLOW'});

    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:site_name', content: 'Pergiumroh.com' });
    this.meta.updateTag({ property: 'og:title', content: config.title });
    this.meta.updateTag({ property: 'og:description', content: config.description });
    this.meta.updateTag({ property: 'og:image', content: config.image });
    this.meta.updateTag({ property: 'og:url', content: 'https://pergiumroh.com' + config.url });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.meta.updateTag({ name: 'twitter:site', content: '@pergiumrohcom' });
    this.meta.updateTag({ name: 'twitter:title', content: config.title });
    this.meta.updateTag({ name: 'twitter:description', content: config.description });
    this.meta.updateTag({ name: 'twitter:image', content: config.image });

  }

  removeMetaTags() {
        this.meta.removeTag('name="description"');
        this.meta.removeTag('name="keywords"');
        // this.meta.removeTag('name="robots"');

        this.meta.updateTag({ name: 'robots', content: 'noindex, nofollow'});

        this.meta.removeTag('name="twitter:card"');
        this.meta.removeTag('name="twitter:site"');
        this.meta.removeTag('name="twitter:title"');
        this.meta.removeTag('name="twitter:description"');
        this.meta.removeTag('name="twitter:image"');

        this.meta.removeTag('property="og:type"');
        this.meta.removeTag('property="og:site_name"');
        this.meta.removeTag('property="og:title"');
        this.meta.removeTag('property="og:description"');
        this.meta.removeTag('property="og:image"');
        this.meta.removeTag('property="og:url"');
   }

}

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { LOCAL_STORAGE } from '@ng-toolkit/universal';

import { Subject, Observable, BehaviorSubject }from  'rxjs';
import { map } from 'rxjs/operators';

import { Config } from '../config';

@Injectable({
  providedIn: 'root'
})
export class CoreAuthService {

  public username: Subject<Object> = new BehaviorSubject<Object>('-');
  public isAuthenticatedCheck: Subject<Object> = new BehaviorSubject<Object>(false);
  public isAuthenticatedCheckAgent: Subject<Object> = new BehaviorSubject<Object>(false);

  constructor(@Inject(LOCAL_STORAGE) private localStorage: any,
              private http: HttpClient,
              private router: Router,
              private config: Config) {
  }


  checkAuthenticatedStatus(status: boolean) {
    this.isAuthenticatedCheck.next(status);
  }

  checkAgentStatus(status: boolean) {
    this.isAuthenticatedCheckAgent.next(status);
  }

  getAgentStatus(): Observable<any> {
       return this.isAuthenticatedCheckAgent.asObservable();
   }

  isAuthenticated() {
    const token = this.localStorage.getItem('token');
    const isAgen = this.localStorage.getItem('isAgen');
    if (token) {
      this.isAuthenticatedCheck.next(true);
      return token !== null;
    } else {
      this.isAuthenticatedCheck.next(false);
      return false;
    }
  }

  isAgen() {
    const token = this.localStorage.getItem('token');
    const isAgent = this.localStorage.getItem('isAgent');
    console.log(isAgent);
    if (token) {
      if (isAgent) {
        this.isAuthenticatedCheckAgent.next(true);
        return true;
      } else {
        this.isAuthenticatedCheckAgent.next(false);
        return false;
      }
    } else {
      return false;
    }
  }





  isPluangMember() {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };
    return this.http
      .post<any[]>(this.config.url + 'profile', '', httpOptions)
      .pipe(map(data => {
        if (data['data']['emasdigi_id'] !== null) {
          return true;
        } else {
          return false;
        }
      }))

  }


  loginEmail(user) {
    return this.http
      .post<any[]>(this.config.url + 'login', {
        email: user.emailOrPhone,
        password: user.password
      })
      .pipe(map(data => data));
  }

  loginPhone(user) {
    return this.http
      .post<any[]>(this.config.url + 'login', {
        phone: user.emailOrPhone,
        password: user.password
      })
      .pipe(map(data => data));
  }

  loginSocialMedia(provider, idToken) {

      // console.log(idToken)

    return this.http
      .post<any>(this.config.url + 'socialLogin', {
          provider: provider,
          access_token: idToken
      })
      .pipe(map(data => data));
  }

  logout() {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };
    return this.http
        .post<any[]>(this.config.url + 'logout', '', httpOptions)
        .pipe(map(data => data));
  }

  getProfile() {
      const token = this.localStorage.getItem('token');
      const httpOptions = {
          headers: new HttpHeaders({
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + token
          })
      };
      return this.http
          .post<any[]>(this.config.url + 'profile', '', httpOptions)
          .pipe(map(data => data));
  }

  updateProfile(user) {
      const token = this.localStorage.getItem('token');
      const httpOptions = {
          headers: new HttpHeaders({
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + token
          })
      };
      return this.http
        .post<any[]>(this.config.url + 'profile-edit', {
            id: user.id,
            name: user.name,
            phone: user.phone
        }, httpOptions)
        .pipe(map(data => data));
  }

  changePassword(user) {
      const token = this.localStorage.getItem('token');
      const httpOptions = {
          headers: new HttpHeaders({
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + token
          })
      };
      return this.http
          .post < any[] > (this.config.url + 'change-password', {
              old_password: user.passwordOld,
              password: user.passwordNew
          }, httpOptions)
          .pipe(map(data => data));
  }

  forgotPasswordEmail(emailOrPhone) {
    return this.http
      .post<any[]>(this.config.url + 'forgotpassword', {
        email: emailOrPhone
      })
      .pipe(map(data => data));
  }

  forgotPasswordPhone(emailOrPhone) {
    return this.http
      .post<any[]>(this.config.url + 'forgotpassword', {
        phone: emailOrPhone
      })
      .pipe(map(data => data));
  }

  verifyPhoneCode(code) {
      const httpOptions = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json'
          })
      };
      return this.http
          .post<any[]>(this.config.url + 'validationPhoneCode', {
              code: code
          }, httpOptions)
          .pipe(map(data => data));
  }

  isEmailAvailable(email) {
      return this.http
          .post<any[]>(this.config.url + 'email', {
              email: email
          })
          .pipe(map(data => data));
  }

  isPhoneAvailable(phone) {
      return this.http
          .post<any[]>(this.config.url + 'phone', {
              phone: phone
          })
          .pipe(map(data => data));
  }

  isPhoneAvailableKudo(phone) {
      return this.http
          .post<any[]>(this.config.url + 'phoneKudo', {
              phone: phone
          })
          .pipe(map(data => data));
  }

  isUsernameAvailable(username) {
      return this.http
          .post<any[]>(this.config.url + 'username', {
              username: username
          })
          .pipe(map(data => data));
  }

  registerViaEmail(form) {
      const httpOptions = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json'
          })
      };
      return this.http
          .post<any[]>(this.config.url + 'register', {
              agent_id: null,
              name: form.name.value,
              email: form.email.value,
              phone: form.phone.value,
              // username: form.username.value,
              password: form.credentials.controls.password.value
          }, httpOptions)
          .pipe(map(data => data));
  }

  registerViaPhone(form) {
      const httpOptions = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json'
          })
      };
      return this.http
          .post<any[]>(this.config.url + 'register', {
              agent_id: null,
              name: form.name.value,
              email: null,
              phone: form.phone.value,
              // username: form.username.value,
              password: form.credentials.controls.password.value
          }, httpOptions)
          .pipe(map(data => data));
  }

  registerKilauUmroh(form) {
      const httpOptions = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json'
          })
      };
      return this.http
          .post<any[]>(this.config.url + 'register', {
              agent_id: null,
              name: form.name.value,
              email: null,
              phone: form.phone.value,
              username: null,
              password: null,
              identity_card_number: form.identityCardNumber.value
          }, httpOptions)
          .pipe(map(data => data));
  }

  registerAgen(form) {
      const httpOptions = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json'
          })
      };
      return this.http
          .post<any[]>(this.config.url + 'register', {
              agent_id: form.agentId.value,
              name: form.name.value,
              email: null,
              phone: form.phone.value,
              username: null,
              password: form.credentials.controls.password.value
          }, httpOptions)
          .pipe(map(data => data));
  }

  sendPhoneVerification(code) {
      const httpOptions = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json'
          })
      };
      return this.http
          .post < any[] > (this.config.url + 'phoneValidation', {
              code: code
          }, httpOptions)
          .pipe(map(
              data => data
          ));
  }

  sendActivation(email) {
      const httpOptions = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json'
          })
      };
      return this.http
          .post < any[] > (this.config.url + 'sendEmailConfirmation', {
              email: email
          }, httpOptions)
          .pipe(map(
              data => data
          ));
  }




  isUserPhoneAvailable(phone) {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };
    return this.http
      .post<any[]>(this.config.url + 'isprofilephoneavailable', {
        phone: phone
      }, httpOptions)
      .pipe(map(data => data));
  }

  sendPhoneVerificationProfile(phone) {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };
    return this.http
      .post<any[]>(this.config.url + 'phoneverification', {
        phone: phone
      }, httpOptions)
      .pipe(map(data => data));
  }
  sendPhoneConfirmationProfile(phone, phone_code) {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };
    return this.http
      .post<any[]>(this.config.url + 'phoneconfirmation', {
        phone: phone,
        phone_code: phone_code
      }, httpOptions)
      .pipe(map(data => data));
  }


}
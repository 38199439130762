import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-agen-payment-confirmation',
  templateUrl: './agen-payment-confirmation.component.html',
  styleUrls: ['./agen-payment-confirmation.component.scss']
})
export class AgenPaymentConfirmationComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  onConfirm() {
    Swal.fire(
        'Berhasil',
        'Terimakasih, pembayaran Anda akan segera kami proses.',
        'success'
    ).then(result => {
      if (result.value) {
        // handle Confirm button click
        // result.value will contain `true` or the input value
        console.log('click ok button')
        this.router.navigate(['/kudo/dashboard']);
      } else {
        // handle dismissals
        // result.dismiss can be 'cancel', 'overlay', 'esc' or 'timer'
        console.log('click dismiss button')
        this.router.navigate(['/kudo/dashboard']);
      }
    });
  }

}

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { LOCAL_STORAGE } from '@ng-toolkit/universal';

import { map } from 'rxjs/operators';

import { Config } from '../config';

@Injectable({
  providedIn: 'root'
})
export class EmasDigiService {

  constructor(@Inject(LOCAL_STORAGE) private localStorage: any,
              private http: HttpClient,
              private config: Config) { }

  getTermsAndConditions() {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };
    return this.http
      .get<any[]>(this.config.emasDigiUrl + 'termsandconditions', httpOptions)
      .pipe(map(data => data));
  }

  postRegister(form: any) {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };
    return this.http
        .post<any[]>(this.config.emasDigiUrl + 'register', {
          name: form.name,
          email: form.email,
          birthdate: form.dob,
          identity_card_number: form.ktp,
          phone: form.phone
        }, httpOptions)
        .pipe(map(data => data));
  }

  postRegisterUser(form: any) {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };
    return this.http
        .post<any[]>(this.config.emasDigiUrl + 'register', {
          user_id: form.id,
          name: form.name,
          email: form.email,
          birthdate: form.dob,
          identity_card_number: form.ktp,
          phone: form.phone,
        }, httpOptions)
        .pipe(map(data => data));
  }

  postRegisterUserKudo(formData) {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };
    return this.http
        .post<any[]>(this.config.emasDigiUrl + 'register', formData, httpOptions)
        .pipe(map(data => data));
  }


  getAccountBalance() {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };
    return this.http
      .get<any[]>(this.config.emasDigiUrl + 'balance', httpOptions)
      .pipe(map(data => data));
  }

  getCurrentGoldPrices() {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };
    return this.http
      .get<any[]>(this.config.emasDigiUrl + 'currentgoldprices', httpOptions)
      .pipe(map(data => data));
  }

  calculateGold(action: string, type, amount) {
    // let params = new HttpParams();
    // params = params.append('action', action);
    // params = params.append('type', type);
    // params = params.append('amount', amount);

    const token = this.localStorage.getItem('token');
    const httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };

      return this.http
        .post<any[]>(this.config.emasDigiUrl + 'calculategold', {
          action: action,
          type: type,
          amount: amount
        }, httpOptions);
  }

  getTransactionHistory(type: string, page: any) {
    const params = new HttpParams();
    // params = params.append('trans_type', type);

    const token = this.localStorage.getItem('token');
    const httpOptions = {
        params: params,
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };

    return this.http
      .post<any[]>(this.config.emasDigiUrl + 'goldtransactionhistory' + page, {
        type: type,
        limit: 7
      }, httpOptions)
      .pipe(map(data => data));
  }


  postBuyGold(price: number) {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };
    return this.http
      .post<any[]>(this.config.emasDigiUrl + 'goldbuy', {
        amount: price
      }, httpOptions)
      .pipe(map(data => data));
  }

}

import { Component, OnInit, Injectable, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';


import { Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbUTCStringAdapter } from '../../shared/ngb-utc-string-adapter';

import { KudoService } from '../../core/services/kudo.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-agen-payment-success',
  templateUrl: './agen-payment-success.component.html',
  styleUrls: ['./agen-payment-success.component.scss'],
  providers: [{
      provide: NgbDateAdapter,
      useValue: 'en-US',
      useClass: NgbUTCStringAdapter
  }]
})
export class AgenPaymentSuccessComponent implements OnInit {

  private _destroyed$ = new Subject();

  total: any = 0;

  currentDate = new Date();

  // FORM DATA
  form: any = {
    from: 'Kudo Teknologi Indonesia',
    bank: '',
    amount: '',
    date: this.formatDate(new Date()),
    time: '',
    proofOfPayment: ''
  }

  orderCodeParams: any;

  paymentImageURL: any;
  
  now = new Date();
  year = this.now.getFullYear();
  month = this.now.getMonth() + 1;
  day = this.now.getDate();
  minDate = {year: this.year, month: this.month, day: this.day};
  maxDate = {year: this.year, month: this.month, day: this.day};


  constructor(@Inject(LOCAL_STORAGE) private localStorage: any,
              private route: ActivatedRoute,
              private router: Router,
              private kudoService: KudoService,
              private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.orderCodeParams = this.route.snapshot.params['order_code'];
    this.spinner.show();
    this.kudoService.getOrder(this.orderCodeParams)
    .pipe(takeUntil(this._destroyed$)).subscribe(
      data => {
        this.total = data['data']['total'];
        this.spinner.hide();
      },
      err => { 
        this.spinner.hide();
      }
    );
  }

  onConfirm() {

    this.spinner.show();

    const formData = new FormData();
    formData.append('from', this.form.from);
    formData.append('bank', this.form.bank);
    formData.append('amount', this.form.amount);
    formData.append('date', this.form.date);
    formData.append('time', this.form.time);
    formData.append('proof_of_payment', this.form.proofOfPayment);

    console.log(formData);

    this.kudoService.paymentConfirmation(formData, this.orderCodeParams)
    .pipe(takeUntil(this._destroyed$)).subscribe(
      data => {
        Swal.fire(
            'Berhasil',
            'Terimakasih, pembayaran Anda akan segera kami proses.',
            'success'
        ).then(result => {
          if (result.value) {
            this.router.navigate(['/kudo/history']);
          } else {
            this.router.navigate(['/kudo/history']);
          }
        });

        this.spinner.hide();
      },
      err => { 
        this.spinner.hide();
      }
    );




    
  }

  onPaymentSelected(event) {
    this.form.proofOfPayment = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(this.form.proofOfPayment); 
    reader.onload = (_event) => { 
      this.paymentImageURL = reader.result; 
    }
  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

}

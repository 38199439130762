import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxSpinnerModule } from 'ngx-spinner';
import { MomentModule } from 'ngx-moment';
import { OwlModule } from 'ngx-owl-carousel';
import { TooltipModule } from 'ng2-tooltip-directive';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// import { NumericDirective } from '../shared/directives/only-number.directive';

import { SocialLoginModule, AuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { ShareButtonsModule } from '@ngx-share/buttons';


import { LimitToPipe } from './pipes/limit-to.pipe';


const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('588212011487-irrc0tkqg0gk95fko9rem9pqnaronc7e.apps.googleusercontent.com')
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('224644452007941')
  }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    LimitToPipe
    // NumericDirective
  ],
  exports: [
    CommonModule,
    NgxSpinnerModule,
    MomentModule,
    OwlModule,
    TooltipModule,
    NgxPaginationModule,
    NgbModule,
    LimitToPipe,
    SocialLoginModule,
    ShareButtonsModule
  ],
  providers: [
     {
       provide: AuthServiceConfig,
       useFactory: provideConfig
     }
   ],
})
export class SharedModule {}

import { Component, OnInit, OnDestroy, Injectable, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LOCAL_STORAGE } from '@ng-toolkit/universal';

import { Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';
import { EmasDigiService } from '../../core/services/emas-digi.service';
import { KudoService } from '../../core/services/kudo.service';

declare var jQuery:any;

@Component({
  selector: 'app-agen-topup-detail',
  templateUrl: './agen-topup-detail.component.html',
  styleUrls: ['./agen-topup-detail.component.scss']
})
export class AgenTopupDetailComponent implements OnInit, OnDestroy {

  private _destroyed$ = new Subject();

  price: number = 50000;
  goldAmount: any = 0; 
  goldPrice: any = 0; 
  total: number = 0;
  math = Math;

  customerPhone: any = this.localStorage.getItem('customerPhone');

  constructor(
              @Inject(LOCAL_STORAGE) private localStorage: any,
              private router: Router,
              private spinner: NgxSpinnerService,
              private emasDigiService: EmasDigiService,
              private kudoService: KudoService) { }

  ngOnInit() {
    this.spinner.show();
    this.emasDigiService.calculateGold('buy', 'currency', this.price)
        .pipe(takeUntil(this._destroyed$))
        .subscribe(
            data => {
                this.goldAmount = data['data']['gold_amount'];
                this.goldPrice = data['data']['gold_price'];
                this.total = this.math.ceil(this.goldAmount * data['data']['gold_price']);
                this.spinner.hide();
            },
            err => {
                this.spinner.hide();
            }
        );
  }

  onPay() {
    console.log('open modal');
    jQuery('#myModal').modal('show');
  }

  onBuy() {
    this.spinner.show();
    this.kudoService.topup(this.goldAmount, this.customerPhone, this.goldPrice, this.total)
        .pipe(takeUntil(this._destroyed$))
        .subscribe(
            data => {
              const orderCode = data['data'];
              this.localStorage.setItem('total', String(this.total));
              this.router.navigate(['/kudo/payment/' + orderCode ]);
              this.spinner.hide();
            },
            err => {
               this.spinner.hide();
            }
        );

    
  }

  onChange(e) {
    this.emasDigiService.calculateGold('buy', 'currency', e)
        .pipe(takeUntil(this._destroyed$))
        .subscribe(
            data => {
              console.log(data)
                this.goldAmount = data['data']['gold_amount'];
                this.goldPrice = data['data']['gold_price'];
                this.total = this.math.ceil(this.goldAmount * data['data']['gold_price']);
                this.spinner.hide();
            },
            err => {
                // console.log(err);
                this.spinner.hide();
            }
        );
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

}
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { Config } from '../config';

@Injectable({
  providedIn: 'root'
})
export class PromoService {

  constructor(private http: HttpClient,
              private config: Config) { }

  getPromos(page: string) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json'
        })
      };
      return this.http
        .post<any[]>(this.config.url + 'promos' + page, {
           limit: 6
        })
        .pipe(map(data => data));
  }

  getPromoBySlug(slug: string, sortBy: string = 'showcase') {

      let params = new HttpParams();
      params = params.append('sortBy', sortBy);

      const httpOptions = {
          params: params,
          headers: new HttpHeaders({
              'Accept': 'application/json'
          })
      };
      return this.http
          .get<any[]>(this.config.url + 'promo/' + slug, httpOptions)
          .pipe(map(data => data));
  }

  getPromoBySlugAgent(slug: string, sortBy: string = 'departureDate') {

      let params = new HttpParams();
      params = params.append('sortBy', sortBy);

      const httpOptions = {
          params: params,
          headers: new HttpHeaders({
              'Accept': 'application/json'
          })
      };
      return this.http
          .get<any[]>(this.config.url + 'promo/daftar-umroh', httpOptions)
          .pipe(map(data => data));
  }

  getAgent(slug) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json'
      })
    };
    return this.http
      .get<any[]>(this.config.url + 'agent/' + slug, httpOptions)
      .pipe(map(data => data));
  }

  getPergibelanjaMyim3() {
    const httpOptions = {
      headers: new HttpHeaders({
          'Accept': 'application/json'
      })
    };
    return this.http
      .get<any>(this.config.url + 'pergibelanja/myim3', httpOptions)
      .pipe(map(data => data));
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';
import { KudoService } from '../../core/services/kudo.service';

@Component({
  selector: 'app-agen-history',
  templateUrl: './agen-history.component.html',
  styleUrls: ['./agen-history.component.scss']
})
export class AgenHistoryComponent implements OnInit, OnDestroy {

  private _destroyed$ = new Subject();

  topupData: any;
  bookingData: any;

  constructor(private spinner: NgxSpinnerService,
              private kudoService: KudoService) { }

  ngOnInit() {
      this.spinner.show();
      this.kudoService.history('topup')
          .pipe(takeUntil(this._destroyed$))
          .subscribe(
              data => {
                this.topupData = data['data']['data'];
                this.spinner.hide();
              },
              err => {
                  this.spinner.hide();
              }
          );

      this.kudoService.history('booking')
          .pipe(takeUntil(this._destroyed$))
          .subscribe(
              data => {
                this.bookingData = data['data']['data'];
                console.log(this.bookingData)
                this.spinner.hide();
              },
              err => {
                  this.spinner.hide();
              }
          );
    

  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

}

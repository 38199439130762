import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthGuardService } from './core/guards/auth.guard';
import { KudoGuardService } from './core/guards/kudo.guard';

import { HomeComponent } from './home/home.component';
import { PageComponent } from './page/page.component';
import { NotFoundComponent } from './not-found/not-found.component';

import { AgenKudoComponent } from './agen-kudo/agen-kudo.component';
import { AgenTopupComponent } from './agen-kudo/agen-topup/agen-topup.component';
import { AgenHargaEmasComponent } from './agen-kudo/agen-harga-emas/agen-harga-emas.component';
import { AgenTopupDetailComponent } from './agen-kudo/agen-topup-detail/agen-topup-detail.component';
import { AgenDashboardComponent } from './agen-kudo/agen-dashboard/agen-dashboard.component';
import { AgenDaftarKilauUmrohComponent } from './agen-kudo/agen-daftar-kilau-umroh/agen-daftar-kilau-umroh.component';
import { AgenPaymentSuccessComponent } from './agen-kudo/agen-payment-success/agen-payment-success.component';
import { AgenPaymentConfirmationComponent } from './agen-kudo/agen-payment-confirmation/agen-payment-confirmation.component';
import { AgenUmrohPackageComponent } from './agen-kudo/agen-umroh-package/agen-umroh-package.component';
import { AgenHistoryComponent } from './agen-kudo/agen-history/agen-history.component';
import { AgenRegisterComponent } from './agen-kudo/agen-register/agen-register.component';
import { AgenFaqComponent } from './agen-kudo/agen-faq/agen-faq.component';

import { VaksinComponent } from './vaksin/vaksin.component';
import { PackageAgentComponent } from './package-agent/package-agent.component';
import { LandingComponent } from './landing/landing.component';
import { PergiBelanjaComponent } from './pergi-belanja/pergi-belanja.component';
import { DetailComponent } from './pergi-belanja/detail/detail.component';
import { QurbanComponent } from './qurban/qurban.component';
import { PaymentComponent } from './qurban/payment/payment.component';
import { Myim3Component } from './pergi-belanja/myim3/myim3.component';

import { MediaComponent } from './media/media.component';

import { UmrohMandiriComponent } from './umroh-mandiri/umroh-mandiri.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'paket-umroh', loadChildren: () => import('./package/package.module').then(m => m.PackageModule)},
  { path: 'blog', loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule)},
  { path: 'promo-umroh', loadChildren: () => import('./promo/promo.module').then(m => m.PromoModule) },
  { path: 'mitra', loadChildren: () => import('./partner/partner.module').then(m => m.PartnerModule) },
  { path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule) },

  { path: 'about', component: PageComponent},
  { path: 'contact', component: PageComponent},
  { path: 'faq', component: PageComponent},
  { path: 'terms', component: PageComponent},
  { path: 'policy', component: PageComponent},
  { path: 'faq-kilau-umroh', component: PageComponent},

  { path: 'vaksin', component: VaksinComponent},
  { path: 'daftar-umroh', component: PackageAgentComponent},
  { path: 'umroh-mandiri', component: UmrohMandiriComponent},

  { path: 'jadwal-umroh', component: PergiBelanjaComponent},
  { path: 'pergibelanja/detail', component: DetailComponent },
  { path: 'pergibelanja/myim3', component: Myim3Component},
  { path: 'pergibelanja/qurban', component: QurbanComponent},
  { path: 'pergibelanja/qurban/payment', component: PaymentComponent},

  { path: 'liputan-media', component: MediaComponent },
  { path: ':slug', component: LandingComponent },

  // { path: 'kudo', component: AgenKudoComponent},
  // { path: 'kudo/register', component: AgenRegisterComponent},
  // { path: 'kudo/topup', component: AgenTopupComponent, canActivate: [AuthGuardService]},
  // { path: 'kudo/topup-detail', component: AgenTopupDetailComponent, canActivate: [AuthGuardService]},
  // { path: 'kudo/harga-emas', component: AgenHargaEmasComponent, canActivate: [AuthGuardService]},
  // { path: 'kudo/dashboard', component: AgenDashboardComponent, canActivate: [AuthGuardService]},
  // { path: 'kudo/kilau-umroh', component: AgenDaftarKilauUmrohComponent, canActivate: [AuthGuardService]},
  // { path: 'kudo/payment/:order_code', component: AgenPaymentSuccessComponent, canActivate: [AuthGuardService]},
  // { path: 'kudo/payment-confirmation', component: AgenPaymentConfirmationComponent, canActivate: [AuthGuardService]},
  // { path: 'kudo/history', component: AgenHistoryComponent, canActivate: [AuthGuardService]},
  // { path: 'kudo/faq', component: AgenFaqComponent, canActivate: [AuthGuardService]},
  // { path: 'kudo/paket-umroh', component: AgenUmrohPackageComponent, canActivate: [AuthGuardService]},

  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],

  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { WINDOW } from '@ng-toolkit/universal';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  Event } from '@angular/router';

declare let ga: Function;
declare var jQuery:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    @Inject(WINDOW) private window:Window,
    @Inject(PLATFORM_ID) private platform: Object,
    private router: Router,
    private title: Title,
    private meta: Meta
  ) {
    router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }

        if (isPlatformBrowser(platform)) {
          window.scrollTo(0, 0);
        }

        jQuery('#account').removeClass('show');
    });
  }

  ngOnInit() {
    this.checkGoogleAnalytics();
  }

  checkGoogleAnalytics() {
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        ga('set', 'page', evt.urlAfterRedirects);
        ga('send', 'pageview');
      }
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

}

import {Pipe,PipeTransform} from '@angular/core';

@Pipe({
    name: 'limitTo'
})
export class LimitToPipe implements PipeTransform {

    transform(value: string, args: string): string {
        const limit = args ? parseInt(args, 10) : 10;
        const trail = '.....';
        let trimmedString = value.substr(0, limit);
        trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')))
        return value.length > limit ? trimmedString + trail : value;
    }

}
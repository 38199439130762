import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Meta, Title } from '@angular/platform-browser';

import { Subject, combineLatest } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';

import { PromoService } from '../core/services/promo.service';
import { SeoService } from '../core/services/seo.service';
import { MetaService } from '../core/services/meta.service';
import { PackageService } from '../core/services/package.service';
import { CoreAuthService } from '../core/services/auth.service';

declare let ga: Function;

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
	private _destroyed$ = new Subject();

  slug: string = this.route.snapshot.params['slug'];

	pageSize: number;
  currentPage: number;
  packageTotal: number;

  landing: any;
  packages: Array<any>;
  cities: Array<any>;
  airlines: Array<any>;

  search = {
    date: '',
    price: '',
    airline: '',
    departure: '',
    sortby: 'showcase',
    tag: ''
  };

  customIndex = 1;

  isReferrer: string;

  check: boolean;
  banners: any;

  constructor(private meta: Meta,
              private title: Title,
              private router: Router,
              private route: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private promoService: PromoService,
              private seoService: SeoService,
              private metaService: MetaService,
              private packageService: PackageService,
              private authService: CoreAuthService) { }

  ngOnInit() {
    if (this.slug == 'myim3') {
      this.title.setTitle('Pergi Qurban dengan Pergiumroh x myIM3');
      this.seoService.generateTags({
        title: 'Pergi Qurban dengan Pergiumroh x myIM3',
        description: 'Qurban di pergiumroh dan dapatkan hadiah menarik bagi kamu pengguna IM3. Yuk! Pergi Qurban bersama pergiumroh dan myIM3',
        keywords: 'Pergi Qurban dengan Pergiumroh x myIM3'
      });
      this.check = true;
    } else {
      this.check = false;
    }

    this.route.queryParams.subscribe(params => {
      this.isReferrer = params['referrer'];
    });
    this.spinner.show();
    this.promoService.getAgent(this.slug)
    .pipe(takeUntil(this._destroyed$))
    .subscribe(
       data => {

         ga('send', 'event', {
          eventCategory: 'Promo',
          eventAction: 'Promo Viewed',
          eventLabel: data['name']
        });
        if (data['data']['slug'] == this.slug) {
          if (this.slug == 'myim3') {
            this.check = true;
            this.banners = [
              {
                'image': data['data']['banner'],
                'link': '#'
              }
            ];
            this.landing = data['data'];

            // SEO
            this.title.setTitle('Pergi Qurban dengan Pergiumroh x myIM3');
            this.seoService.generateTags({
              title: 'Pergi Qurban dengan Pergiumroh x myIM3',
              description: 'Qurban di pergiumroh dan dapatkan hadiah menarik bagi kamu pengguna IM3. Yuk! Pergi Qurban bersama pergiumroh dan myIM3',
              keywords: 'Pergi Qurban dengan Pergiumroh x myIM3'
            });
          } else {
            this.check = false;
            this.landing = data['data'];
            this.initData();
          }

        } else {
          this.router.navigate([this.slug, '']);
        }

        // META
        // console.log(data);
        // this.title.setTitle(data['data']['seo_title']);
        // this.meta.updateTag({ name: 'description', content: data['data']['seo_metadesc'] });
        // this.meta.updateTag({ name: 'keywords', content: data['data']['seo_metakeyword'] });
        // this.meta.updateTag({ property: 'og:title', content: data['data']['seo_title'] });
        // this.meta.updateTag({ property: 'og:description', content: data['data']['seo_metadesc'] });
        // this.meta.updateTag({ property: 'og:image', content: data['data']['banner'] });
        // this.meta.updateTag({ property: 'twitter:title', content: data['data']['seo_title'] });
        // this.meta.updateTag({ property: 'twitter:description', content: data['data']['seo_metadesc'] });
        // CORE


        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
      }
    )

  }

  initData() {
    let currentPage = this.route.snapshot.queryParams['page'];
    if (currentPage === undefined) {
      currentPage = 1;
      this.customIndex = 1;
    } else {
      this.customIndex = parseInt(currentPage);
    }
    this.spinner.show();

    if (this.authService.isAuthenticatedCheckAgent['value']) {
        this.spinner.show();
      this.search.sortby = 'price';
      if (this.search.tag == '') {
        this.search.tag = '';
      } else {
        this.search.tag = this.search.tag;
      }

      this.packageService.searchPackages(this.search, '?page=1')
        .subscribe(
            data => {
              this.packages = data['data'];
              this.packageTotal = data['meta']['total'];
              this.pageSize = data['meta']['per_page'];
              this.router.navigate([this.slug], { queryParams: { sort: 'price' }, queryParamsHandling: 'merge' });
              this.spinner.hide();
            },
            err => {
              this.spinner.hide();
            }
        );
    } else {
        combineLatest(
        this.metaService.getMetaPackage(),
        this.packageService.getPackages('?page=' + currentPage, this.search.tag),
        this.packageService.getCities(),
        this.packageService.getAirlines()
      ).pipe(
        map(([meta, packageData, city, airline]) => ({meta, packageData, city, airline })),
        take(1),
        takeUntil(this._destroyed$)
      ).subscribe(
        data => {
          // SEO
          this.title.setTitle(data['meta']['data']['seo_title']);
          this.seoService.generateTags({
            title: data['meta']['data']['seo_title'],
            description: data['meta']['data']['seo_metadesc'],
            keywords: data['meta']['data']['seo_metakeyword']
          });

          // core
          // check search from home or not
          if (this.packageService.searchFromHome) {
            this.packages = this.packageService.searchResult;
            this.packageTotal = this.packageService.packageTotal;
            this.pageSize = this.packageService.pageSize;
            this.currentPage = this.packageService.currentPage;

            this.search.date = this.packageService.searchTemp.date;
            this.search.price = this.packageService.searchTemp.price;
            this.search.airline = this.packageService.searchTemp.airline;
            this.search.tag = this.packageService.searchTemp.tag;

          } else {
            this.packages = data['packageData']['data'];
            this.packageTotal = data['packageData']['meta']['total'];
            this.pageSize = data['packageData']['meta']['per_page'];
            this.currentPage = data['packageData']['meta']['current_page'];
          }



          // detail
          this.cities = data['city']['data'];
          this.airlines = data['airline']['data'];

          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
          // console.log('error package list');
          // console.error(err);
        },
      );
    }

  }

  onSortBy(value) {
      this.spinner.show();
      this.search.sortby = value;
      this.search.tag = '';
      this.packageService.searchPackagesFromLanding(this.search, '?page=1')
        .subscribe(
            data => {
              this.packages = data['data'];
              this.packageTotal = data['meta']['total'];
              this.pageSize = data['meta']['per_page'];
              this.router.navigate([this.slug], { queryParams: { sort: value }, queryParamsHandling: 'merge' });
              this.spinner.hide();
            },
            err => {
              // console.log('error sortby');
              // console.log(err);
              this.spinner.hide();
            }
        );
   }

  pageChanged(event) {
      this.spinner.show();
      this.packageService.searchPackagesFromLanding(this.search, '?page=' + event)
          .subscribe(
              data => {
                  this.packages = data['data'];
                  this.packageTotal = data['meta']['total'];
                  this.pageSize = data['meta']['per_page'];
                  this.currentPage = event;
                  this.router.navigate([this.slug], { queryParams: { page: event }, queryParamsHandling: 'merge' });
                  if (event === 0) { this.customIndex = 1; }
                  this.customIndex =  event;
                  this.spinner.hide();
              },
              () => {
                this.spinner.hide();
              }
          );
  }

  onSearchPackages() {

      this.spinner.show();
      this.search.sortby = this.search.sortby;
      this.search.tag = '';

      // console.log('airline')
      // console.log(this.search)

      this.packageService.searchPackages(this.search, '?page=1')
          .subscribe(
              data => {
                // console.log(data)
                  this.packages = data['data'];
                  this.packageTotal = data['meta']['total'];
                  this.pageSize = data['meta']['per_page'];
                  this.currentPage = data['meta']['current_page'];

                  this.router.navigate([this.slug], {
                    queryParams: {
                      departure: this.search.departure,
                      price: this.search.price,
                      airline: this.search.airline,
                      sortby: this.search.sortby
                    },
                    queryParamsHandling: 'merge'
                  });
                  this.spinner.hide();
              },
              () => {
                // console.log('error search package');
                // console.error(err);
                this.spinner.hide();
              }
          );
  }

}

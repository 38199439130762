import { Component, OnInit, OnDestroy, Inject, ElementRef, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

import { Subject, combineLatest, fromEvent, Observable } from 'rxjs';
import { map, takeUntil, merge } from 'rxjs/operators';

import { NgxSpinnerService} from 'ngx-spinner';

import { SeoService } from '../core/services/seo.service';
import { MetaService } from '../core/services/meta.service';
import { HomeService } from '../core/services/home.service';
import { PackageService } from '../core/services/package.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  private _destroyed$ = new Subject();

  @ViewChild('scrollableTarget', { static: false}) scrollableTarget: ElementRef;

  banners: Array<any>;
  testimonials: Array<any>;
  airlines: Array<any>;
  packagesByTag: Array<any>;
  medias: Array<any>;
  randomPartners: Array<any>;
  blogs: Array<any>;

  search = {
    date: '',
    price: '',
    airline: ''
  };

  paymentLogo = [{
    name: 'logo doku',
    url: 'https://doku.com',
    image: 'https://storage.googleapis.com/pastiumroh/assets/payments/logo1.png',
    show: false
  }, {
    name: 'logo permata net',
    url: 'https://new.permatanet.com/',
    image: 'https://storage.googleapis.com/pastiumroh/assets/payments/logo12.png',
    show: false
  }, {
    name: 'logo visa',
    url: 'https://usa.visa.com/',
    image: 'https://storage.googleapis.com/pastiumroh/assets/payments/logo8.png',
    show: false
  }, {
    name: 'logo mastercard',
    url: 'https://www.mastercard.us/',
    image: 'https://storage.googleapis.com/pastiumroh/assets/payments/logo7.png',
    show: false
  }, {
    name: 'logo internet banking muamalat',
    url: 'https://ib.muamalatbank.com/ib-app/loginpage',
    image: 'https://storage.googleapis.com/pastiumroh/assets/payments/logo15.png',
    show: false
  }, {
    name: 'logo bank danamon',
    url: 'https://www.danamon.co.id/',
    image: 'https://storage.googleapis.com/pastiumroh/assets/payments/logo14.png',
    show: false
  }, {
    name: 'logo bfi syariah',
    url: 'https://syariah.bfi.co.id/',
    image: 'https://storage.googleapis.com/pastiumroh/assets/payments/logo-bfi-syariah.png',
    show: false
  }, {
    name: 'logo permata bank syariah',
    url: 'https://www.permatabank.com/Syariah/',
    image: 'https://storage.googleapis.com/pastiumroh/assets/payments/logo13.png',
    show: false
  }, {
    name: 'logo kredit pro',
    url: 'http://kreditpro.id/',
    image: 'https://storage.googleapis.com/pastiumroh/assets/payments/logo2.png',
    show: false
  }, {
    name: 'logo bca syariah',
    url: 'https://www.bcasyariah.co.id/',
    image: 'https://storage.googleapis.com/pastiumroh/assets/payments/bca-syariah.jpg',
    show: false
  }];


  // scrollableTarget2$: any;
  scrollableTarget2: any;
  contentLoaded: Subject<any> = new Subject();
  loadAndScroll: Observable<any>;




  constructor(private meta: Meta,
              private title: Title,
              private router: Router,
              private route: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private seoService: SeoService,
              private metaService: MetaService,
              private homeService: HomeService,
              private packageService: PackageService,
              private elementRef:ElementRef) {
                // console.log('hei')
                // this.scrollTarget = document.getElementsByClassName('.media.0').addEventListener('scroll', () => console.log('Im scrolling'))

                // console.log('bro')
                // console.log(bro)

                // console.log(el);
                // window.addEventListener('scroll', () => console.log('Im scrolling'))
                

                
              }

  ngOnInit() {
    this.initData();

  }


  initData() {
    this.spinner.show();
    combineLatest(
        this.metaService.getMetaHomepage(),
        this.homeService.getBanners(),
        this.homeService.getTestimonials('pergiumroh')
      ).pipe(
        map(([meta, banner, testimonial]) => ({ meta, banner, testimonial })),
        takeUntil(this._destroyed$)
      ).subscribe(
        data => {
          // SEO
          this.title.setTitle(data['meta']['data']['seo_title']);
          this.seoService.generateTags({
            title: data['meta']['data']['seo_title'],
            description: data['meta']['data']['seo_metadesc'],
            keywords: data['meta']['data']['seo_metakeyword']
          });

          // this.elementRef.nativeElement.querySelector('#scrolltest0').addEventListener('scroll', () => console.log('Im scrolling'))
          

          // META
          this.title.setTitle(data['meta']['data']['seo_title']);
          this.meta.addTag({ name: 'description', content: data['meta']['data']['seo_metadesc'] });
          this.meta.addTag({ name: 'keywords', content: data['meta']['data']['seo_metakeyword'] });
          this.meta.addTag({ name: 'robots', content: 'index, follow' });

          // BANNER
          this.banners = data['banner']['data'];
          this.testimonials = data['testimonial']['data'];
          if (this.testimonials[0] == undefined) {
            this.testimonials = null;
          }
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        },
      );

      combineLatest(
          this.packageService.getPackageByTag(),
          this.packageService.getAirlines(),
          this.homeService.getRandomPartners(6),
          this.homeService.getBlogs(4),
          this.homeService.getMedias()
        ).pipe(
          map(([packageData, airline, randomPartner, blog,  media]) => ({ packageData, airline, randomPartner, blog, media })),
          takeUntil(this._destroyed$)
        ).subscribe(
          data => {
            this.packagesByTag = data['packageData']['data'];
            // console.log(this.packagesByTag.length);
            for (let i = 0; i < this.packagesByTag.length; i++) {
              this.packagesByTag[i]['show'] = false;
              for (let j = 0; j < this.packagesByTag[i]['packages']['items'].length; j++) {
                this.packagesByTag[i]['packages']['items'][j]['show'] = false;
              }
            }

          

            this.airlines = data['airline']['data'];
            this.medias = data['media']['data'];
            for (let i = 0; i < this.medias.length; i++) {
              this.medias[i]['show'] = false;
            }
            this.randomPartners = data['randomPartner']['data'];
            for (let i = 0; i < this.randomPartners.length; i++) {
              this.randomPartners[i]['show'] = false;
            }
            this.blogs = data['blog']['data'];
            console.log(this.blogs)
          },
        () => {
            this.spinner.hide();
          },
        );
  }

  scroll() {
    // console.log('scrolll')
  }

  onSearchPackages() {
    this.packageService.searchTemp.date = this.search.date;
    this.packageService.searchTemp.price = this.search.price;
    this.packageService.searchTemp.airline = this.search.airline;
    this.spinner.show();
    this.packageService.searchPackagesFromHome(this.search, '?page=1');
  }

  onSearchPackagesByTag(tag: string) {
    this.packageService.searchTemp.tag = tag;
    this.spinner.show();
    this.packageService.searchPackagesByTagFromHome(this.search, '?page=1', tag);
  }


  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

}

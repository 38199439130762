import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { LOCAL_STORAGE } from '@ng-toolkit/universal';

import { map } from 'rxjs/operators';

import { Config } from '../config';

import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class PackageService {

  searchTemp: any = {
    date: '',
    price: '',
    airline: '',
    tag: ''
  };
  searchFromHome = false;

  searchResult: any;
  pageSize: number;
  currentPage: number;
  packageTotal: number;

  constructor(@Inject(LOCAL_STORAGE) private localStorage: any,
              private http: HttpClient,
              private config: Config,
              private router: Router,
              private spinner: NgxSpinnerService) { }

  getPackages(page: string, tag: string) {
    const httpOptions = {
      headers: new HttpHeaders({
          'Accept': 'application/json'
      })
    };
    return this.http
      .post<any>(this.config.url + 'search/packages' + page, {
          limit: 6,
          sortby: 'showcase',
          tag: tag
      }, httpOptions)
      .pipe(map(data => data));
  }

  getPackagesFromLanding(page: string) {
    const httpOptions = {
      headers: new HttpHeaders({
          'Accept': 'application/json'
      })
    };
    return this.http
      .post<any>(this.config.url + 'search/packages' + page, {
          limit: 8,
          sortby: 'showcase'
      }, httpOptions)
      .pipe(map(data => data));
  }

  searchPackages(form: any, page) {
      const httpOptions = {
          headers: new HttpHeaders({
              'Accept': 'application/json'
          })
      };
      return this.http
        .post<any>(this.config.url + 'search/packages' + page, {
          limit: 6,
          // keyword: form.keyword,
          departure: form.departure,
          // duration: form.duration,
          price: form.price,
          date: form.date,
          airline_id: form.airline,
          sortBy: form.sortby,
          // travel_id: form.userId,
          tag: form.tag
        }, httpOptions)
        .pipe(map(data => data));
  }
  searchPackagesFromLanding(form: any, page) {
      const httpOptions = {
          headers: new HttpHeaders({
              'Accept': 'application/json'
          })
      };
      return this.http
        .post<any>(this.config.url + 'search/packages' + page, {
          limit: 8,
          // keyword: form.keyword,
          departure: form.departure,
          // duration: form.duration,
          price: form.price,
          date: form.date,
          airline_id: form.airline,
          sortBy: form.sortby,
          // travel_id: form.userId,
          tag: form.tag
        }, httpOptions)
        .pipe(map(data => data));
  }
  searchPackagesFromHome(form: any, page) {
      const httpOptions = {
          headers: new HttpHeaders({
              'Accept': 'application/json'
          })
      };
      return this.http
        .post<any>(this.config.url + 'search/packages' + page, {
          limit: 6,
          price: form.price,
          date: form.date,
          airline_id: form.airline,
          sortBy: 'showcase'
        }, httpOptions)
        .pipe(map(data => data))
        .subscribe(
            data => {

                this.searchResult = data['data'];
                this.packageTotal = data['meta']['total'];
                this.pageSize = data['meta']['per_page'];
                this.currentPage = data['meta']['current_page'];
                this.searchFromHome = true;
                this.router.navigate(['/paket-umroh'], {
                  queryParams: {
                    departure: this.searchTemp.date,
                    price: this.searchTemp.price,
                    airline: this.searchTemp.airline,
                  },
                  queryParamsHandling: 'merge'
                });
            }
        );
  }

  searchPackagesByTag(form: any, page) {
      const httpOptions = {
          headers: new HttpHeaders({
              'Accept': 'application/json'
          })
      };
      return this.http
        .post<any>(this.config.url + 'search/packages' + page, {
          limit: 6,
          // keyword: form.keyword,
          departure: form.departure,
          // duration: form.duration,
          price: form.price,
          date: form.date,
          airline_id: form.airline,
          sortBy: form.sortby,
          // travel_id: form.userId,
          tag: form.tag
        }, httpOptions)
        .pipe(map(data => data));
  }

  searchPackagesByTagFromHome(form: any, page: string, tag: string) {
      const httpOptions = {
          headers: new HttpHeaders({
              'Accept': 'application/json'
          })
      };
      return this.http
        .post<any>(this.config.url + 'search/packages' + page, {
          limit: 6,
          tag: tag
        }, httpOptions)
        .pipe(map(data => data))
        .subscribe(
            data => {
              this.searchResult = data['data'];
              this.packageTotal = data['meta']['total'];
              this.pageSize = data['meta']['per_page'];
              this.currentPage = data['meta']['current_page'];
              this.searchFromHome = true;
              this.router.navigate(['/paket-umroh'], {
                queryParams: {
                  tag: tag
                },
                queryParamsHandling: 'merge'
              });
              this.spinner.hide();
            }
        );
  }

  // searchPackageByTag(page: string) {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //         'Accept': 'application/json'
  //     })
  //   };
  //   return this.http
  //     .post<any>(this.config.url + 'search/packages' + page, {
  //         limit: 6,
  //         tag: 'ini tag'
  //     }, httpOptions)
  //     .pipe(map(data => data));
  // }

  getPackageById(id: string) {
    return this.http
        .get<any>(this.config.url + 'package/' + id)
        .pipe(map(data => data));
  }

  getPackageBySlug(slug: string) {
    return this.http
        .get<any>(this.config.url + 'package/' + slug)
        .pipe(map(data => data));
  }

  getPackageByTag() {
      return this.http
        .post<any> (this.config.url + 'tags/packages', { limit: 4 })
        .pipe(map(data => data));
  }

  getPackagesByTravelId(page: string, travelId) {
      const token = this.localStorage.getItem('token');
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };
      return this.http
        .post<any[]>(this.config.url + 'search/packages' + page, {
          limit: 8,
          travel_id: travelId
        }, httpOptions)
        .pipe(map(data => data));
  }

  getRandomPackages(travelId: number) {
    const httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json'
        })
    };
    return this.http
        .post<any>(this.config.url + 'search/packages', {
            limit: 4,
            sortby: 'random',
            travel_id: travelId
        }, httpOptions)
        .pipe(map(data => data));
  }

  getAirlines() {
    return this.http
      .post<any[]>(this.config.url + 'airlines', {
          limit: 10
      })
      .pipe(map(data => data));
  }

  postRequestHelp(form: any) {
    return this.http
      .post<any[]>(this.config.url + 'booking', {
        package_id: form.packageId,
        name: form.name,
        email: form.email,
        phone: form.phone,
        call_available: 1
      })
      .pipe(map(data => data));
  }

  getCities() {
    return this.http
      .post<any[]>(this.config.url + 'cities', {
          limit: 25
      })
      .pipe(map(data => data));
  }

  getWishlists() {
      const token = this.localStorage.getItem('token');
      const httpOptions = {
          headers: new HttpHeaders({
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + token
          })
      };
      return this.http
        .post<any[]>(this.config.url + 'wishlists', {
            limit: 16
        }, httpOptions)
        .pipe(map(data => data));
  }

  // addWishlist(packageId: any) {
  //     const token = this.localStorage.getItem('token');
  //     const httpOptions = {
  //         headers: new HttpHeaders({
  //             'Accept': 'application/json',
  //             'Authorization': 'Bearer ' + token
  //         })
  //     };
  //     return this.http
  //         .post<any[]>(this.config.url + 'wishlist', {
  //             package_id: packageId
  //         }, httpOptions)
  //         .pipe(map(data => data));
  // }

  postCustomPackage(form) {
      const token = this.localStorage.getItem('token');
      const httpOptions = {
          headers: new HttpHeaders({
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + token
          })
      };
      return this.http
          .post < any[] > (this.config.url + 'custom', {
              user_id: form.user_id,
              pilgrims: form.pilgrims,
              duration: form.duration,
              departure_date: form.departure_date,
              additional_tour_1: form.additional_tour_1,
              additional_tour_2: form.additional_tour_2,
              airlines_1: form.airlines_1,
              airlines_2: form.airlines_2,
              airlines_3: form.airlines_3,
              mecca_rating: form.mecca_rating,
              medina_rating: form.medina_rating,
              additional_city_1: form.additional_city_1,
              additional_city_2: form.additional_city_2,
              hotel_1: form.hotel_1,
              hotel_2: form.hotel_2,
              hotel_3: form.hotel_3,
              hotel_4: form.hotel_4
          }, httpOptions)
          .pipe(map(data => data));
  }

}

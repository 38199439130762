import {
 HttpEvent,
 HttpInterceptor,
 HttpHandler,
 HttpRequest,
 HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';
import { CoreAuthService } from './services/auth.service';

@Injectable({
  providedIn: 'root'
})

export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(@Inject(LOCAL_STORAGE) private localStorage: any,
              private router: Router,
              private authService: CoreAuthService) {}

 intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   return next.handle(request)
     .pipe(
       retry(1),
       catchError((error: HttpErrorResponse) => {
         let errorMessage = '';
         if (error.error instanceof ErrorEvent) {
           // client-side error
           errorMessage = `Error: ${error.error.message}`;
           // console.log('interceptor')
           // console.log('client side error')
         } else {
           if (error.status === 401) {
             this.localStorage.clear();

             if (this.router.url === '/kudo') {
               this.router.navigate(['/kudo']);
             } else {
               // this.router.navigate(['/']);
             }

             // this.authService.isAuthenticatedCheck = false;
             this.authService.checkAuthenticatedStatus(false)
           }
           errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
         }
         // window.alert(errorMessage);
         return throwError(errorMessage);
       })
     );
 }
}

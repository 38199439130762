import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { Config } from '../config';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(private http: HttpClient,
              private config: Config) { }

  getMetaHomepage() {
    return this.http
      .get<any[]>(this.config.url + 'page/homepage')
      .pipe(map(data => data));
  }

  getMetaPackage() {
    return this.http
      .get<any[]>(this.config.url + 'page/package')
      .pipe(map(data => data));
  }

  getMetaPartner() {
    return this.http
      .get<any[]>(this.config.url + 'page/partner')
      .pipe(map(data => data));
  }

  getMetaBlog() {
    return this.http
      .get<any[]>(this.config.url + 'page/blog')
      .pipe(map(data => data));
  }

  getMetaAbout() {
    return this.http
      .get<any[]>(this.config.url + 'page/about')
      .pipe(map(data => data));
  }

  getMetaContact() {
    return this.http
      .get<any[]>(this.config.url + 'page/contact')
      .pipe(map(data => data));
  }

  getMetaFaq() {
    return this.http
      .get<any[]>(this.config.url + 'page/frequently-asked-questions')
      .pipe(map(data => data));
  }

  getMetaPrivacyPolicy() {
    return this.http
      .get<any[]>(this.config.url + 'page/privacy-policy')
      .pipe(map(data => data));
  }

  getMetaTerms() {
    return this.http
      .get<any[]>(this.config.url + 'page/terms-and-conditions')
      .pipe(map(data => data));
  }

  getMetaKilauUmroh() {
    return this.http
      .get<any[]>(this.config.url + 'page/faq-kilau-umroh')
      .pipe(map(data => data));
  }

  getMetaCustom() {
    return this.http
      .get<any[]>(this.config.url + 'page/custom-package')
      .pipe(map(data => data));
  }

  getMetaPromo() {
    return this.http
      .get<any[]>(this.config.url + 'page/promo')
      .pipe(map(data => data));
  }

  getSyaratKetentuanPergiBelanja() {
    return this.http
      .get<any[]>(this.config.url + 'page/syarat-ketentuan-pergibelanja')
      .pipe(map(data => data));
  }

  getMetaPergiBelanja() {
    return this.http
      .get<any[]>(this.config.url + 'page/pergibelanja')
      .pipe(map(data => data));
  }

}

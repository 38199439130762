import { Component, OnInit, OnDestroy, ElementRef, Inject } from '@angular/core';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  Event } from '@angular/router';

import { LOCAL_STORAGE } from '@ng-toolkit/universal';

import { Subject, timer, Subscription } from 'rxjs';
import { map, takeUntil, filter, takeWhile } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";

import { CoreAuthService } from '../../core/services/auth.service';
import { CountdownService } from '../../core/services/countdown.service';
import { KilauUmrohService } from '../../core/services/kilau-umroh.service';

import Swal from 'sweetalert2';

declare var jQuery:any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  private _destroyed$ = new Subject();
  subscription: Subscription;

  openNavigation: boolean = false;

  loginForm = {
    emailOrPhone: '',
    password: ''
  };

  registerForm: FormGroup;

  forgotPasswordForm = {
    forgotEmailOrPhone: '',
    smsConfirmationCode: ''
  };

  resendForm = {
    email: ''
  };

  tempRegisterPhone: string;

  loginErrorStatus: boolean = false;
  loginErrorText: string = "E-mail atau password yang Anda masukkan salah.";

  forgotErrorStatus: boolean = false;
  forgotErrorText: string = "Pastikan alamat email atau no handphone yang anda masukan benar.";

  forgotSmsErrorStatus: boolean = false;
  forgotSmsErrorText: string = "Gagal, kode tidak sesuai.";
  registerSmsErrorStatus: boolean = false;
  registerSmsErrorText: string = "Gagal, kode tidak sesuai.";

  // check is login or not
  isAuthenticated: boolean = false;
  registerSmsConfirmationCode: any;

  EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
  PHONE_REGEXP = /^(^\+62\s?|^0)(\d{3,4}?){2}\d{3,4}$/g;

  emasDigi: boolean = false;

  counter: any = 0;
  isResend = true;

  isMember = false;

  currentRoute: string;
  isPergibelanja: boolean = false;

  constructor(@Inject(LOCAL_STORAGE) private localStorage: any,
              private router: Router,
              private spinner: NgxSpinnerService,
              public authService: CoreAuthService,
              private socialAuthService: AuthService,
              private elementRef: ElementRef,
              public  countdownService: CountdownService,
              private kilauUmrohService: KilauUmrohService ) {
    this.subscription = this.authService.getAgentStatus().subscribe(status => { console.log('agent status detected'); console.log(status) });

    router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }

        console.log(evt.url)

        if (evt.url === '/jadwal-umroh') {
            this.isPergibelanja = true;
        } else {
            this.isPergibelanja = false;
        }

    });

  }

  ngOnInit() {
      this.authService.isAuthenticated();
      this.authService.isAgen();
      this.authService.username.next(this.localStorage.getItem('name'));

      // this.isAuthenticated = this.authService.isAuthenticatedCheck;
      this.registerForm = new FormGroup({
          'name': new FormControl(null, Validators.required),
          'email': new FormControl(null, null, this.checkEmailInput.bind(this)),
          'phone': new FormControl(null, [Validators.required, Validators.pattern('[0-9]*')], this.checkPhoneInput.bind(this)),
          'credentials': new FormGroup({
              'password': new FormControl(null, [Validators.required, Validators.minLength(6)]),
              'confirmPassword': new FormControl(null, [Validators.required, Validators.minLength(6)])
          }, this.isPasswordMatch)
      });


      // console.log('router')
      // console.log(this.router.url)

      // if (this.router.url === '/pergibelanja') {
      //     this.isPergibelanja = true;
      //     console.log('hei')
      //     console.log(this.isPergibelanja)
      // }


      // this.kilauUmrohService.isMember()
      // .pipe(takeUntil(this._destroyed$)).subscribe(
      //   data => {
      //     this.isMember = true;
      //     this.spinner.hide();
      //   },
      //   err => {
      //     this.isMember = false;
      //     this.spinner.hide();
      //   },
      // );


      // this.authService.getProfile()
      // .pipe(takeUntil(this._destroyed$)).subscribe(
      //   data => {
      //     const emasDigiId = data['data']['emasdigi_id'];
      //     if (emasDigiId !== null) {
      //       this.emasDigi = true;
      //     } else {
      //       this.emasDigi = false;
      //     }
      //     this.spinner.hide();
      //   },
      //   err => {
      //     console.log('error');
      //     console.log(err);
      //     this.spinner.hide();
      //   },
      // )

  }

  resendSms() {
    if (this.isResend) {
      this.countdownService.start(50);
    }
    this.countdownService.countdown()
    .pipe(takeUntil(this._destroyed$))
    .subscribe(
      data => {
        this.counter = data;
        this.counter = this.counter < 10 ? '0' + this.counter : this.counter;
        this.isResend = false;
      },
      null,
      () => {
        this.isResend = true;
      }
    );
  }


  /*
  ==================================================
  START LOGIN
  ==================================================
  */
  onLogin() {
      if (this.EMAIL_REGEXP.test(this.loginForm.emailOrPhone)) {
          this.onLoginViaEmail();
      } else if (this.PHONE_REGEXP.test(this.loginForm.emailOrPhone)) {
          this.onLoginViaPhone();
      } else {
          this.spinner.show();
          setTimeout(() => {
              this.loginErrorStatus = true;
              this.loginErrorText = "Email atau nomor handphone yang kamu masukkan tidak valid.";
              this.loginForm.password = "";
              this.spinner.hide();
          }, 500);
      }
  }
  onLoginViaEmail() {
      this.spinner.show();
      this.authService.loginEmail(this.loginForm)
          .subscribe(
              data => {
                  const isVerification = data['data']['status'];
                  if (isVerification === "0") {
                      this.loginErrorStatus = true;
                      this.loginErrorText = "Akun belum aktif, silahkan verifikasi akun terlebih dahulu.";
                      this.loginForm.password = "";
                  } else {
                      this.localStorage.setItem('token', data['access_token']);
                      this.localStorage.setItem('name', data['data']['name']);
                      this.authService.username.next(data['data']['name']);
                      this.loginForm.emailOrPhone = "";
                      this.loginForm.password = "";
                      this.authService.checkAuthenticatedStatus(true);
                      jQuery('#loginModal').modal('hide');
                      this.loginErrorStatus = false;
                  }
                  this.spinner.hide();
              },
              err => {
                  this.loginErrorStatus = true;
                  this.loginErrorText = "E-mail atau password yang Anda masukkan salah.";
                  this.loginForm.password = "";
                  this.spinner.hide();
              }
          );
  }
  onLoginViaPhone() {
      this.spinner.show();
      this.authService.loginPhone(this.loginForm)
          .subscribe(
              data => {
                  const isVerification = data['data']['status'];
                  if (isVerification === "0") {
                      this.loginErrorStatus = true;
                      this.loginErrorText = "Akun belum aktif, silahkan verifikasi akun terlebih dahulu.";
                      this.loginForm.password = "";
                  } else {
                      this.localStorage.setItem('token', data['access_token']);
                      this.localStorage.setItem('name', data['data']['name']);
                      this.authService.username.next(data['data']['name']);
                      this.loginForm.emailOrPhone = "";
                      this.loginForm.password = "";
                      this.authService.checkAuthenticatedStatus(true);
                      this.loginErrorStatus = false;
                      jQuery('#loginModal').modal('hide');
                  }
                  this.spinner.hide();
              },
              err => {
                  this.loginErrorStatus = true;
                  this.loginErrorText = "Nomor handphone atau password yang Anda masukkan salah.";
                  this.loginForm.password = "";
                  this.spinner.hide();
              }
          );
  }
  signInWithGoogle() {
      this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(
          (userData) => {
            console.log(userData)
              this.spinner.show();
              this.authService.loginSocialMedia('google', userData.idToken)
                  .subscribe(
                      data => {
                          this.localStorage.setItem('token', data['data']['access_token']);
                          this.localStorage.setItem('name', data['data']['name']);
                          this.authService.username.next(data['data']['name']);
                          this.authService.checkAuthenticatedStatus(true);
                          jQuery('#loginModal').modal('hide');
                          this.loginErrorStatus = false;
                          this.spinner.hide();
                      },
                      err => {
                          console.log('error')
                          console.log(err)
                          this.registerForm.patchValue({
                              'name': userData.name,
                              'emailOrPhone': userData.email
                          });
                          jQuery('#loginModal').modal('hide');
                          jQuery('#registerModal').modal('show');
                          this.spinner.hide();
                      }
                  );
          }
      );
  }
  /*
  ==================================================
  END LOGIN
  ==================================================
  */



  /*
  ==================================================
  START REGISTER
  ==================================================
  */
  onRegister() {
    console.log(this.registerForm.controls.phone);
      if (this.registerForm.valid) {
          this.register();
          // console.log('true')
      } else {
          this.validateAllFormFields(this.registerForm);
          // console.log('false')
      }
  }
  register() {
      if (this.EMAIL_REGEXP.test(this.registerForm.controls.email.value)) {
        console.log('register')
        console.log(this.registerForm.controls.email.value)
          this.spinner.show();
          this.authService.registerViaEmail(this.registerForm.controls)
              .subscribe(
                  data => {
                      jQuery('#registerModal').modal('hide');
                      this.registerForm.reset();
                      this.spinner.hide();
                      Swal.fire(
                          'Berhasil',
                          'Silahkan cek email anda. Kami telah mengirim pesan konfirmasi ke alamat e-mail <span class="font-weight-semibold">' + data['data']['email'] + '</span>',
                          'success'
                      );
                  },
                  err => {
                    // console.log(err)
                      this.spinner.hide();
                      jQuery('#registerModal').modal('hide');
                  }
              );
      } else {
        this.spinner.show();
        this.authService.registerViaPhone(this.registerForm.controls)
            .subscribe(
                data => {
                    jQuery('#registerModal').modal('hide');
                    jQuery('#confirmRegisterSmsModal').modal('show');
                    this.tempRegisterPhone = data['data']['phone'];
                    this.registerForm.reset();
                    this.spinner.hide();
                },
                err => {
                    this.spinner.hide();
                    jQuery('#registerModal').modal('hide');
                }
            );
      }
  }
  onVerifyRegister(form: NgForm) {
      this.spinner.show()
      this.authService.sendPhoneVerification(form.value.verify)
          .subscribe(
              data => {
                  Swal.fire(
                      'Berhasil',
                      'Akun sudah sudah diverifikasi, silahkan login.',
                      'success'
                  );
                  jQuery('#confirmRegisterSmsModal').modal('hide');
                  this.registerSmsErrorStatus = false;
                  this.spinner.hide();
              },
              err => {
                  this.spinner.hide();
                  this.registerSmsErrorStatus = true;
              },
          );

  }
  /*
  ==================================================
  END REGISTER
  ==================================================
  */



  /*
  ==================================================
  FORGOT PASSWORD
  ==================================================
  */
  onForgotPassword() {
      const currentEmailOrPhone = this.forgotPasswordForm.forgotEmailOrPhone;
      this.spinner.show();
      if (this.EMAIL_REGEXP.test(currentEmailOrPhone)) {
          this.authService.forgotPasswordEmail(currentEmailOrPhone)
              .subscribe(
                  data => {
                      jQuery('#forgotPasswordModal').modal('hide');
                      Swal.fire(
                          'Berhasil',
                          'Silahkan cek email anda',
                          'success'
                      );
                      this.spinner.hide();
                  },
                  err => {
                      this.forgotErrorStatus = true;
                      this.forgotPasswordForm.forgotEmailOrPhone = "";
                      this.spinner.hide();
                  }
              );
      }

      if (this.PHONE_REGEXP.test(currentEmailOrPhone)) {
          this.authService.forgotPasswordPhone(currentEmailOrPhone)
              .subscribe(
                  data => {
                      jQuery('#forgotPasswordModal').modal('hide');
                      jQuery('#confirmForgotSmsModal').modal('show');
                      this.spinner.hide();
                  },
                  err => {
                      this.forgotErrorStatus = true;
                      this.forgotPasswordForm.forgotEmailOrPhone = "";
                      this.spinner.hide();
                  }
              );
      }
  }
  onVerify() {
      this.spinner.show();
      this.authService.verifyPhoneCode(this.forgotPasswordForm.smsConfirmationCode)
          .subscribe(
              data => {
                  window.location.href = 'https://dashboard.pergiumroh.com/forgotpassword/' + data['token'];
                  this.forgotSmsErrorStatus = false;
                  this.forgotSmsErrorText = "";
                  this.spinner.hide();
              },
              err => {
                  this.forgotSmsErrorStatus = true;
                  this.spinner.hide();
              },
          );
  }
  /*
  ==================================================
  END PASSWORD
  ==================================================
  */


  /*
  ==================================================
  RESEND
  ==================================================
  */
  onResendActivation() {
      this.spinner.show();
          this.authService.sendActivation(this.resendForm.email)
            .subscribe(
                data => {
                    jQuery('#sendActivationModal').modal('hide');
                    Swal.fire(
                        'Berhasil',
                        'Silahkan cek email anda',
                        'success'
                    );
                    this.resendForm.email = "";
                    this.spinner.hide();
                },
                err => {
                    this.resendForm.email = "";
                    this.spinner.hide();
                }
            );
  }




  openNav() {
      this.openNavigation = true;
      document.body.style.backgroundColor = 'rgba(0,0,0,0.4)';
  }

  closeNav() {
      this.openNavigation = false;
      document.body.style.backgroundColor = 'rgba(255,255,255,1)';
  }

  validateAllFormFields(formGroup: FormGroup) {
      Object.keys(formGroup.controls).forEach(field => {
          const control = formGroup.get(field);
          if (control instanceof FormControl) {
              control.markAsTouched({
                  onlySelf: true
              });
          } else if (control instanceof FormGroup) {
              this.validateAllFormFields(control);
          }
      });
  }

  onLogout() {
    this.spinner.show();
    this.authService.logout()
        .subscribe(
            data => {
                this.authService.checkAuthenticatedStatus(false);
                this.authService.checkAgentStatus(false);
                // this.authService.isAuthenticatedCheckAgent = false;
                this.localStorage.clear();
                this.router.navigate(['/']);
                this.spinner.hide();
            },
            err => {
                // this.authService.isAuthenticatedCheck = false;
                // this.authService.isAuthenticatedCheckAgent = false;
                this.authService.checkAuthenticatedStatus(false);
                this.authService.checkAgentStatus(false);
                this.localStorage.clear();
                this.router.navigate(['/']);
                this.spinner.hide();
            }
        );
  }



  /*
  ==================================================
  START CUSTOM VALIDATOR
  ==================================================
  */
  checkEmailPattern(control: FormControl): {
      [s: string]: boolean
  } {
      const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

          if (this.EMAIL_REGEXP.test(control.value)) {
              return null;
          } else if (control.value == null) {
              return null;
          } else {
              return {
                  'emailIsNotValid': true
              };
          }
  }
  // checkPhonePattern(control: FormControl): {
  //     [s: string]: boolean
  // } {
  //     const PHONE_REGEXP = /^(^\+62\s?|^0)(\d{3,4}?){2}\d{3,4}$/g;

  //     if (this.PHONE_REGEXP.test(control.value)) {
  //         return null;
  //     } else if (control.value == null) {
  //         return null;
  //     } else {
  //         return {
  //             'phoneIsNotValid': true
  //         };
  //     }
  // }

  checkEmailInput(control: FormControl): Promise < any > | Observable < any > {
        const promise = new Promise < any > ((resolve, reject) => {
        this.authService.isEmailAvailable(this.registerForm.controls.email.value)
            .subscribe(
                (response: any[]) => resolve(null),
                (error) => resolve({
                    'emailNotAvailable': true
                })
            );
        });
        return promise;
  }
  checkPhoneInput(control: FormControl): Promise < any > | Observable < any > {
      const promise = new Promise < any > ((resolve, reject) => {
          this.authService.isPhoneAvailable(this.registerForm.controls.phone.value)
              .subscribe(
                  (response: any[]) => resolve(null),
                  (error) => resolve({
                      'phoneNotAvailable': true
                  })
              );

      });
      return promise;
  }

  // checkUsernameInput(control: FormControl): Promise < any > | Observable < any > {
  //    const promise = new Promise < any > ((resolve, reject) => {
  //        this.authService.isUsernameAvailable(this.registerForm.controls.username.value)
  //            .subscribe(
  //                (response: any[]) => resolve(null),
  //                (error) => resolve({
  //                    'usernameNotAvailable': true
  //                })
  //            );
  //    });
  //    return promise;
  // }
  isPasswordMatch(form: FormGroup) {
      if (form.get('password').value !== form.get('confirmPassword').value) {
          return {
              'passwordNotMatch': true
          };
      }
      return null;
  }
  /*
  ==================================================
  END CUSTOM VALIDATOR
  ==================================================
  */


  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

}

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { LOCAL_STORAGE } from '@ng-toolkit/universal';

import { map } from 'rxjs/operators';

import { Config } from '../config';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(@Inject(LOCAL_STORAGE) private localStorage: any,
    private http: HttpClient,
              private config: Config) { }

  getProgram() {
    return this.http
        .get<any>(this.config.url + 'program')
        .pipe(map(data => data));
  }

  // pergibelanja
  getProducts(sortBy = 'default', page, category, keyword) {

     let params = new HttpParams();
     params = params.append('sortBy', sortBy);
     params = params.append('page', page);
     params = params.append('category', category);
     params = params.append('keyword', keyword);
     const httpOptions = {
         params: params,
     };


    // if (sortBy == null && sortBy == 'default') {
    //   return this.http
    //       .get<any>(this.config.url + 'pergibelanja')
    //       .pipe(map(data => data));
    // }

    return this.http
        .get<any>(this.config.url + 'pergibelanja', httpOptions)
        .pipe(map(data => data));


  }

  getBannerPergibelanja(type) {
    const httpOptions = {
      headers: new HttpHeaders({
          'Accept': 'application/json'
      })
    };
    return this.http
      .get<any>(this.config.url + 'pergibelanja/banners?type='+type, httpOptions)
      .pipe(map(data => data));
  }

  getSubBannerPergibelanja() {
    const httpOptions = {
      headers: new HttpHeaders({
          'Accept': 'application/json'
      })
    };
    return this.http
      .get<any>(this.config.url + 'pergibelanja/banner/7', httpOptions)
      .pipe(map(data => data));
  }

  getPopupPergibelanja() {
    return this.http
        .get<any>(this.config.url + 'pergibelanja/popup')
        .pipe(map(data => data));
  }

  // Start Custom Umroh
  getListCustomUmroh(page) {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
      })
    };
    return this.http
      .get<any>(this.config.url + 'custom-umroh/list' + page, httpOptions)
      .pipe(map(data => data));
  }

  getCitiesDepartureFrom() {
    return this.http
        .get<any>(this.config.url + 'custom-umroh/departure-cities')
        .pipe(map(data => data));
  }

  getAllAirline(timeDept, deptPort, duration) {
    return this.http
        .get<any>(this.config.url + 'custom-umroh/flights', {
          params: {
            'time_dept': timeDept,
            'port_dept': deptPort,
            'duration' : duration
          }
        })
        .pipe(map(data => data));
  }

  getAllHotel(star) {
    return this.http
        .get<any>(this.config.url + 'custom-umroh/hotels', {
          params: {
            'star': star,
          }
        })
        .pipe(map(data => data));
  }

  getStatusRequest() {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
      })
    };
    return this.http
      .get<any>(this.config.url + 'custom-umroh/check-status', httpOptions)
      .pipe(map(data => data));
  }

  previewCustomUmroh(dataOrder, departureDate, duration, departureFrom, pilgrim, airline, hotel, otherService) {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
      })
    };
    return this.http
      .post<any>(this.config.url + 'custom-umroh/preview', {
          dataOrder: dataOrder,
          departureDate: departureDate,
          duration: duration,
          departureFrom: departureFrom,
          pilgrim: pilgrim,
          airline: airline,
          hotel: hotel,
          otherService: otherService,
      }, httpOptions)
      .pipe(map(data => data));
  }

  requestCustomUmroh(dataOrder, departureDate, duration, departureFrom, pilgrim, airline, hotel, otherService) {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
      })
    };
    return this.http
      .post<any>(this.config.url + 'custom-umroh', {
          dataOrder: dataOrder,
          departureDate: departureDate,
          duration: duration,
          departureFrom: departureFrom,
          pilgrim: pilgrim,
          airline: airline,
          hotel: hotel,
          otherService: otherService,
      }, httpOptions)
      .pipe(map(data => data));
  }

  // End Custom Umroh

  getBrandPergibelanja() {
    const httpOptions = {
      headers: new HttpHeaders({
          'Accept': 'application/json'
      })
    };
    return this.http
      .post<any>(this.config.url + 'pergibelanja/brands', {
          limit: 12,
      }, httpOptions)
      .pipe(map(data => data));
  }

  searchBrandPergibelanja(e) {
    const httpOptions = {
      headers: new HttpHeaders({
          'Accept': 'application/json'
      })
    };
    return this.http
      .post<any>(this.config.url + 'pergibelanja/searchBrand', {
          limit: 12,
          search: e,
      }, httpOptions)
      .pipe(map(data => data));
  }

  getCategoryPergibelanja() {
    return this.http
        .get<any>(this.config.url + 'pergibelanja/categories')
        .pipe(map(data => data));
  }

  getCategoryDetailPergibelanja(id) {
    return this.http
        .get<any>(this.config.url + 'pergibelanja/categories/' + id)
        .pipe(map(data => data));
  }

  getTransactionPergibelanja(type) {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
      })
    };
    return this.http
      .post<any>(this.config.url + 'pergibelanja/transactions', {
          limit: 10,
          type: type
      }, httpOptions)
      .pipe(map(data => data));

  }

  getWithdrawPergibelanja() {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
      })
    };
    return this.http
      .post<any>(this.config.url + 'pergibelanja/withdraws', {
          limit: 10,
      }, httpOptions)
      .pipe(map(data => data));
  }

  getCheckWithdrawPergibelanja() {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token,
      })
    };
    return this.http
        .get<any>(this.config.url + 'pergibelanja/check-withdraw', httpOptions)
        .pipe(map(data => data));
  }

  requestWithdraw(phone, provider, nominal, bank_owner, bank_name, bank_no, usernameShopee) {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
      })
    };
    return this.http
      .post<any>(this.config.url + 'pergibelanja/withdraw', {
          phone: phone,
          provider: provider,
          nominal: nominal,
          bank_owner: bank_owner,
          bank_name: bank_name,
          bank_no: bank_no,
          usernameShopee: usernameShopee,
      }, httpOptions)
      .pipe(map(data => data));
  }

  getBankUserPergibelanja() {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
      })
    };
    return this.http
      .get<any>(this.config.url + 'pergibelanja/bank', httpOptions)
      .pipe(map(data => data));
  }

  getBankUserCreateOrUpdatePergibelanja(name, name_bank, no_bank) {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
      })
    };
    return this.http
      .post<any>(this.config.url + 'pergibelanja/bank/createOrUpdate', {
        name: name,
        name_bank: name_bank,
        no_bank: no_bank,
      }, httpOptions)
      .pipe(map(data => data));
  }

  // Qurban
  qurban(formData, paymentDoku) {
    console.log(formData, paymentDoku)
    const httpOptions = {
      headers: new HttpHeaders({
          'Accept': 'application/json'
      })
    };
    return this.http
      .post< any[] >(this.config.url + 'qurbans', {
        'donaturs': formData.donaturs.value,
        'infos': formData.infos.value,
        'transactions': {
          'amount': paymentDoku.amount,
          'transidmerchant': paymentDoku.transidmerchant,
          'words': paymentDoku.words,
          'paymentchannel': paymentDoku.paymentchannel,
          'currency': paymentDoku.currency,
          'purchasecurrency': paymentDoku.purchasecurrency
        }
      }, httpOptions)
      .pipe(map(data => data));
  }

  getQurbanList() {
    return this.http
        .get<any>(this.config.url + 'qurban')
        .pipe(map(data => data));
  }

  getQurbanSetting() {
    return this.http
        .get<any>(this.config.url + 'qurban/setting')
        .pipe(map(data => data));
  }

}

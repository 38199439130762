import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { LOCAL_STORAGE } from '@ng-toolkit/universal';

import { Subject, Observable, BehaviorSubject }from  'rxjs';
import { map } from 'rxjs/operators';

import { Config } from '../config';

@Injectable({
  providedIn: 'root'
})
export class KudoService {

  constructor(@Inject(LOCAL_STORAGE) private localStorage: any,
              private http: HttpClient,
              private router: Router,
              private config: Config) {
  }


  login(phone, password) {
    return this.http
      .post<any[]>(this.config.kudoUrl + 'login', {
        phone: phone,
        password: password
      })
      .pipe(map(data => data));
  }

  register(userData) {
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
    return this.http
        .post<any[]>(this.config.kudoUrl + 'register', {
            agent_id: userData.agentId.value,
            name: userData.name.value,
            phone: userData.phone.value,
            password: userData.credentials.controls.password.value
        }, httpOptions)
        .pipe(map(data => data));
  }

  registerKilauUmroh(formData) {
      const token = this.localStorage.getItem('token');
      const httpOptions = {
          headers: new HttpHeaders({
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + token
          })
      };

      return this.http
        .post<any[]>(this.config.kudoUrl + 'registerKilauUmroh', formData, httpOptions)
        .pipe(map(data => data));
  }

  isPhoneAvailable(phone) {
     const token = this.localStorage.getItem('token');
     return this.http
      .post<any[]>(this.config.kudoUrl + 'isPhoneAvailable', {
          phone: phone
      })
      .pipe(map(data => data));
  }

  isTopupPhoneAvailable(phone) {
     const token = this.localStorage.getItem('token');
     return this.http
      .post<any[]>(this.config.kudoUrl + 'isTopupPhoneAvailable', {
          phone: phone
      })
      .pipe(map(data => data));
  }

  isKtpAvailable(identityCardNumber) {
     const token = this.localStorage.getItem('token');
     return this.http
      .post<any[]>(this.config.kudoUrl + 'isKtpAvailable', {
          identity_card_number: identityCardNumber
      })
      .pipe(map(data => data));
  }

  isCustomerPhoneAvailable(phone) {
     const token = this.localStorage.getItem('token');
     const httpOptions = {
         headers: new HttpHeaders({
             'Content-Type': 'application/json',
             'Authorization': 'Bearer ' + token
         })
     };
     return this.http
      .post<any[]>(this.config.kudoUrl + 'isCustomerPhoneAvailable', {
          phone: phone
      }, httpOptions)
      .pipe(map(data => data));
  }

  buyPackage(packageId, name, phone) {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };
    return this.http
      .post<any[]>(this.config.kudoUrl + 'package', {
          package_id: packageId,
          name: name,
          phone: phone
      }, httpOptions)
      .pipe(map(data => data));
  }

  topup(goldAmount, customerPhone, buyPrice, total) {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };
    return this.http
      .post<any[]>(this.config.kudoUrl + 'topup', {
          gold: goldAmount,
          customer_phone: customerPhone,
          buy_price: buyPrice,
          total: total
      }, httpOptions)
      .pipe(map(data => data));
  }

  history(type) {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };
    return this.http
      .post<any[]>(this.config.kudoUrl + 'history', {
          type: type
      }, httpOptions)
      .pipe(map(data => data));
  }

  getOrder(orderCode) {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };
    return this.http
      .get<any[]>(this.config.kudoUrl + 'payment/' + orderCode, httpOptions)
      .pipe(map(data => data));
  }

  paymentConfirmation(formData, orderCode) {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };
    return this.http
      .post<any[]>(this.config.kudoUrl + 'payment/' + orderCode, formData, httpOptions)
      .pipe(map(data => data));
  }

}

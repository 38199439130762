import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';
import { map } from 'rxjs/operators';
import { Config } from '../config';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {

  constructor(@Inject(LOCAL_STORAGE) private localStorage: any,
              private http: HttpClient,
              private config: Config) { }

  registerVaccine(formData) {
    const token = localStorage.getItem('token');
    const httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };
    return this.http
      .post<any[]>(this.config.url + 'vaccine', formData, httpOptions)
      .pipe(map(data => data));
  }

}

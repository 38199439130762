import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbUTCStringAdapter } from '../shared/ngb-utc-string-adapter';

import { Subject, combineLatest } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';
import { CoreAuthService } from '../core/services/auth.service';
import { FeatureService } from '../core/services/feature.service';
import { PartnerService } from '../core/services/partner.service';
import { SettingService } from '../core/services/setting.service';

import Swal from 'sweetalert2';
declare var jQuery:any;
declare let ga: Function;

@Component({
  selector: 'app-vaksin',
  templateUrl: './vaksin.component.html',
  styleUrls: ['./vaksin.component.scss'],
  providers: [{
      provide: NgbDateAdapter,
      useValue: 'en-US',
      useClass: NgbUTCStringAdapter
  }]
})
export class VaksinComponent implements OnInit {

  private _destroyed$ = new Subject();

  @ViewChild('ktp', {static: false}) ktpFile: ElementRef;
  @ViewChild('selfie', {static: false}) selfieFile: ElementRef;
  @ViewChild('passport', {static: false}) passportFile: ElementRef;

  now = new Date();
  year = this.now.getFullYear();
  month = this.now.getMonth() + 1;
  day = this.now.getDate();
  minDate = {year: this.year, month: this.month, day: this.day + 3};
  maxDate = {year: this.year, month: this.month, day: this.day};

  isChecked = false;
  isNotAgree = true;
  isBannerShow = false;


  vaksinForm: FormGroup;
  vaccineGroup: FormGroup;
  packageGroup: FormGroup
  genderGroup: FormGroup

  banner: any;
  travels: any;
  schedule: any;
  gender = [
    {
      name: 'Laki-laki',
      code: 'L'
    },
    {
      name: 'Perempuan',
      code: 'P'
    }
  ]

  ktpSelectedFile = null;
  selfieSelectedFile = null;
  passportSelectedFile = null;
  ktpURL: any;
  selfieURL: any;
  passportURL: any;

  constructor(
    public fb: FormBuilder,
    private spinner: NgxSpinnerService,
    public authService: CoreAuthService,
    private featureService: FeatureService,
    private partnerService: PartnerService,
    private settingService: SettingService) { }

  ngOnInit() {

    this.vaksinForm = new FormGroup({
        'name': new FormControl(null, [Validators.required]),
        'date_of_birth': new FormControl(null, [Validators.required]),
        'phone': new FormControl(null, [Validators.required, Validators.pattern('[0-9]*')]),
        'email': new FormControl(null, [Validators.required]),
        'location': new FormControl(null, [Validators.required]),
        'identity_card_number': new FormControl(null, [Validators.required, Validators.pattern('[0-9]*')]),
        'passport_number': new FormControl(null, [Validators.required, Validators.pattern('[0-9]*')]),
        'travel_id': new FormControl(null, [Validators.required]),
        'departure_date': new FormControl(null, [Validators.required]),
        'total_pilgrims': new FormControl(null, [Validators.required])
    });

    this.genderGroup = this.fb.group({
      gender: ['', Validators.required]
    });

    this.vaccineGroup = this.fb.group({
      vaccine_schedule: ['', Validators.required]
    });

    this.packageGroup = this.fb.group({
      package_from: ['', Validators.required]
    });

    this.vaksinForm.patchValue({ total_pilgrims: 1 });

    this.spinner.show();



    combineLatest(
      this.partnerService.getPartners('?page=1', 40),
      this.settingService.getSetting('vaccine_banner'),
      this.settingService.getSetting('vaccine_schedule')
    ).pipe(
      map(([partner, banner, schedule]) => ({partner, banner, schedule })),
      takeUntil(this._destroyed$)).subscribe(
      data => {

        ga('send', 'event', {
          eventCategory: 'Vaccine',
          eventAction: 'Vaccine List Viewed'
        });

        if (this.authService.isAuthenticated()) {
            // 
        } else {
            jQuery('#loginModal').modal('show');
        }
        this.travels = data['partner']['data'];

        this.banner = data['banner']['data']['value'];
        if (data['banner']['data']['value'] !== null) {
          this.isBannerShow = true;
        }

        this.schedule = data['schedule']['data']['value'];

        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
      }
    );

    // this.settingService.getSetting('vaccine_banner')
    // .pipe(takeUntil(this._destroyed$)).subscribe(
    //   settingData => {
    //     this.banner = settingData['data']['value'];
    //     if (settingData['data']['value'] !== null) {
    //       this.isBannerShow = true;
    //     }
        
    //     this.spinner.hide();
    //   },
    //   err => {
    //     this.isBannerShow = false;
    //     this.spinner.hide();
    //   }
    // );

    // this.settingService.getSetting('vaccine_schedule')
    // .pipe(takeUntil(this._destroyed$)).subscribe(
    //   settingData => {
    //     this.schedule = settingData['data']['value'];
    //     this.spinner.hide();
    //   },
    //   err => {
    //     this.isBannerShow = false;
    //     this.spinner.hide();
    //   }
    // );

    this.vaksinForm.controls['travel_id'].setValue('', {onlySelf: true})

  }

  onSubmit() {
    if (this.vaksinForm.valid && this.genderGroup.valid && this.packageGroup.valid && this.vaccineGroup.valid) {
      this.postData();
    } else {
      this.validateAllFormFields(this.vaksinForm);
      this.validateAllFormFields(this.genderGroup);
      this.validateAllFormFields(this.packageGroup);
      this.validateAllFormFields(this.vaccineGroup);
    }
  }

  postData() {
    const formData = new FormData();
    formData.append('name', this.vaksinForm.controls.name.value);
    formData.append('date_of_birth', this.vaksinForm.controls.date_of_birth.value);
    formData.append('gender', this.genderGroup.controls.gender.value);
    formData.append('phone', this.vaksinForm.controls.phone.value);
    formData.append('email', this.vaksinForm.controls.email.value);
    formData.append('location', this.vaksinForm.controls.location.value);
    formData.append('package_from', this.packageGroup.controls.package_from.value);
    formData.append('passport_number', this.vaksinForm.controls.passport_number.value);
    formData.append('identity_card_number', this.vaksinForm.controls.identity_card_number.value);
    formData.append('ktp', this.ktpSelectedFile);
    formData.append('selfie', this.selfieSelectedFile);
    formData.append('passport', this.passportSelectedFile);
    formData.append('travel_id', this.vaksinForm.controls.travel_id.value);
    formData.append('departure_date', this.vaksinForm.controls.departure_date.value);
    formData.append('total_pilgrims', this.vaksinForm.controls.total_pilgrims.value);
    formData.append('vaccine_schedule', this.vaccineGroup.controls.vaccine_schedule.value);


    this.spinner.show();
    this.featureService.registerVaccine(formData)
    .pipe(takeUntil(this._destroyed$)).subscribe(
      data => {

        ga('send', 'event', {
          eventCategory: 'Vaccine',
          eventAction: 'Vaccine Submit'
        });

        this.vaksinForm.reset();
        this.genderGroup.reset();
        this.vaccineGroup.reset();
        this.packageGroup.reset();
        this.isNotAgree = true;
        this.vaksinForm.patchValue({ total_pilgrims: 1 });
        this.vaksinForm.controls['travel_id'].setValue('', {onlySelf: true})

        this.ktpFile.nativeElement.value = null;
        this.selfieFile.nativeElement.value = null;
        this.passportFile.nativeElement.value = null;

        this.ktpURL = null;
        this.selfieURL = null;
        this.passportURL = null;



        console.log('first = ' + this.isChecked)

        this.isChecked = false;

        console.log('after = ' + this.isChecked)

        Swal.fire(
            'Berhasil',
            'Pendaftaran Vaksin Berhasil',
            'success'
        );
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
      }
    );

  }

  // onAgree(event) {
  //   this.isNotAgree = !event.target.checked;
  //   this.isChecked = !this.isChecked;
  // }



  /*
  |--------------------------------------------------------------------------
  | VALIDATE & PATTERN
  |--------------------------------------------------------------------------
  */
  validateAllFormFields(formGroup: FormGroup) {
      Object.keys(formGroup.controls).forEach(field => {
          const control = formGroup.get(field);
          if (control instanceof FormControl) {
              control.markAsTouched({
                  onlySelf: true
              });
          } else if (control instanceof FormGroup) {
              this.validateAllFormFields(control);
          }
      });
  }



  /*
  |--------------------------------------------------------------------------
  | IMAGE
  |--------------------------------------------------------------------------
  */
  onKtpSelected(event) {
    this.ktpSelectedFile = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(this.ktpSelectedFile); 
    reader.onload = (_event) => { 
      this.ktpURL = reader.result; 
    };
  }

  onSelfieSelected(event) {
    this.selfieSelectedFile = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(this.selfieSelectedFile);
    reader.onload = (_event) => {
      this.selfieURL = reader.result;
    }
  }

  onPassportSelected(event) {
    this.passportSelectedFile = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(this.passportSelectedFile);
    reader.onload = (_event) => {
      this.passportURL = reader.result;
    }
  }

  onAgree(event) {
    if (event.target.checked) {
      this.isNotAgree = false;
      this.isChecked = true;
    } else {
      this.isNotAgree = true;
      this.isChecked = false;
    }

    console.log(this.isChecked)
  }

}

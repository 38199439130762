import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

import { NgxSpinnerService } from 'ngx-spinner';

import { SeoService } from '../core/services/seo.service';

import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { GeneralService } from '../core/services/general.service';

@Component({
  selector: 'app-qurban',
  templateUrl: './qurban.component.html',
  styleUrls: ['./qurban.component.scss']
})
export class QurbanComponent implements OnInit {

    private _destroyed$ = new Subject();

    setting: any;
	qurbans: any;
    isLoad: boolean = false;


  constructor(
    private title: Title,
    private router: Router,
    private spinner: NgxSpinnerService,
    private seoService: SeoService,
    private generalService: GeneralService) { }

  ngOnInit() {
    this.spinner.show();

    this.generalService.getQurbanList()
    .pipe(
      take(1),
      takeUntil(this._destroyed$))
    .subscribe(
       data => {
        // Seo
        this.title.setTitle('Qurban');
        this.seoService.generateTags({
          title: 'Qurban',
          description: 'Qurban',
          keywords: 'Qurban'
        });

        this.qurbans = data['data'];

        this.isLoad = true;


        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
      }
    );

    this.generalService.getQurbanSetting()
    .pipe(
      take(1),
      takeUntil(this._destroyed$))
    .subscribe(
       data => {

        this.setting = data['data'];

        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
      }
    );



  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

}

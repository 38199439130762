import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { Meta, Title } from '@angular/platform-browser';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';

import { SeoService } from '../core/services/seo.service';
import { MetaService } from '../core/services/meta.service';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit, OnDestroy {
  private _destroyed$ = new Subject();

  currentRoute: string;
  content: any;

  constructor(private meta: Meta,
              private title: Title,
              private router: Router,
              private route: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private sanitizer: DomSanitizer,
              private seoService: SeoService,
              private metaService: MetaService) { }

  ngOnInit() {
    this.currentRoute = this.router.url;
    if (this.currentRoute === '/about') {
        this.metaService.getMetaAbout()
            .pipe(takeUntil(this._destroyed$))
            .subscribe(
                data => {
                    // console.log(data);
                    this.content = this.sanitizer.bypassSecurityTrustHtml(data['data']['content']);

                    // SEO
                    this.title.setTitle(data['data']['seo_title']);
                    this.seoService.generateTags({
                      title: data['data']['seo_title'],
                      description: data['data']['seo_metadesc'],
                      keywords: data['data']['seo_metakeyword']
                    });

                    this.spinner.hide();
                },
                () => { this.spinner.hide(); }
            );
    } else if (this.currentRoute === '/contact') {
        this.metaService.getMetaContact()
            .pipe(takeUntil(this._destroyed$))
            .subscribe(
                data => {
                    this.content = this.sanitizer.bypassSecurityTrustHtml(data['data']['content']);

                    // SEO
                    this.title.setTitle(data['data']['seo_title']);
                    this.seoService.generateTags({
                      title: data['data']['seo_title'],
                      description: data['data']['seo_metadesc'],
                      keywords: data['data']['seo_metakeyword']
                    });

                    this.spinner.hide();
                },
                () => { this.spinner.hide(); }
            );
    } else if (this.currentRoute === '/faq') {
        this.metaService.getMetaFaq()
            .pipe(takeUntil(this._destroyed$))
            .subscribe(
                data => {
                    this.content = this.sanitizer.bypassSecurityTrustHtml(data['data']['content']);
                    // SEO
                    this.title.setTitle(data['data']['seo_title']);
                    this.seoService.generateTags({
                      title: data['data']['seo_title'],
                      description: data['data']['seo_metadesc'],
                      keywords: data['data']['seo_metakeyword']
                    });

                    this.spinner.hide();
                },
                () => { this.spinner.hide(); }
            );
    } else if (this.currentRoute === '/terms') {
        this.metaService.getMetaTerms()
            .pipe(takeUntil(this._destroyed$))
            .subscribe(
                data => {
                    this.content = this.sanitizer.bypassSecurityTrustHtml(data['data']['content']);
                    // SEO
                    this.title.setTitle(data['data']['seo_title']);
                    this.seoService.generateTags({
                      title: data['data']['seo_title'],
                      description: data['data']['seo_metadesc'],
                      keywords: data['data']['seo_metakeyword']
                    });

                    this.spinner.hide();
                },
                () => { this.spinner.hide(); }
            );
    } else if (this.currentRoute === '/faq-kilau-umroh') {
        this.metaService.getMetaKilauUmroh()
            .pipe(takeUntil(this._destroyed$))
            .subscribe(
                data => {
                    this.content = this.sanitizer.bypassSecurityTrustHtml(data['data']['content']);
                    // SEO
                    this.title.setTitle(data['data']['seo_title']);
                    this.seoService.generateTags({
                      title: data['data']['seo_title'],
                      description: data['data']['seo_metadesc'],
                      keywords: data['data']['seo_metakeyword']
                    });

                    this.spinner.hide();
                },
                () => { this.spinner.hide(); }
            );
    } else if (this.currentRoute === '/404') {
        this.meta.updateTag({name: 'robots', content: 'noindex' });
        this.spinner.hide();
    } else {
        this.metaService.getMetaPrivacyPolicy()
            .pipe(takeUntil(this._destroyed$))
            .subscribe(
                data => {
                    this.content = this.sanitizer.bypassSecurityTrustHtml(data['data']['content']);
                    // SEO
                    this.title.setTitle(data['data']['seo_title']);
                    this.seoService.generateTags({
                      title: data['data']['seo_title'],
                      description: data['data']['seo_metadesc'],
                      keywords: data['data']['seo_metakeyword']
                    });

                    this.spinner.hide();
                },
                () => { this.spinner.hide(); }
            );
    }
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

}

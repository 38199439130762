import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

import { LOCAL_STORAGE } from '@ng-toolkit/universal';

import { KudoService } from '../../core/services/kudo.service';

@Component({
  selector: 'app-agen-umroh-package',
  templateUrl: './agen-umroh-package.component.html',
  styleUrls: ['./agen-umroh-package.component.scss']
})
export class AgenUmrohPackageComponent implements OnInit {
  packageId: any = this.localStorage.getItem('packageId');
  name: any;
  phone: any;

  errorStatus = false;
  errorText: string = "No Handphone yang Anda masukkan sudah pernah didaftarkan.";

  constructor(@Inject(LOCAL_STORAGE) private localStorage: any,
              private router: Router,
              private spinner: NgxSpinnerService,
              public kudoService: KudoService) { }

  ngOnInit() {
  }




  onBuyUmrohPackage() {
    this.spinner.show();
    this.kudoService.buyPackage(this.packageId, this.name, this.phone)
        .subscribe(
            data => {
              this.spinner.hide();
              Swal.fire(
                  'Berhasil',
                  'Pemesanan Paket Umroh Berhasil',
                  'success'
              ).then(result => {
                if (result.value) {
                  this.router.navigate(['/kudo/dashboard']);
                } else {
                  this.router.navigate(['/kudo/dashboard']);
                }
              });
              // this.errorStatus = false;
            },
            err => {
              // this.errorStatus = true;
               this.spinner.hide();
            }
        );
  }

}

import { Component, OnInit, OnDestroy, Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

import { LOCAL_STORAGE } from '@ng-toolkit/universal';

import { Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';
import { KudoService } from '../../core/services/kudo.service';

@Component({
  selector: 'app-agen-topup',
  templateUrl: './agen-topup.component.html',
  styleUrls: ['./agen-topup.component.scss']
})
export class AgenTopupComponent implements OnInit, OnDestroy {
   
  private _destroyed$ = new Subject();

  phone: any;

  errorStatus = false;
  errorText: string = "No Handphone yang Anda masukkan belum terdaftar.";

  topupForm: FormGroup;

  constructor(@Inject(LOCAL_STORAGE) private localStorage: any,
              private spinner: NgxSpinnerService,
              private router: Router,
              public kudoService: KudoService) { }

  ngOnInit() {
    this.topupForm = new FormGroup({
      'phone': new FormControl(null, [Validators.required, Validators.pattern('[0-9]*')], this.checkPhoneInput.bind(this)),
    });
  }

  onBuy() {
    this.spinner.show();
    this.kudoService.isTopupPhoneAvailable(this.topupForm.controls.phone.value)
    .subscribe(
        data => {
          this.localStorage.setItem('customerPhone', String((this.topupForm.controls.phone.value)));
          this.router.navigate(['/kudo/topup-detail']);
          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
        }
    );
  }

  checkPhoneInput(control: FormControl): Promise < any > | Observable < any > {
      const promise = new Promise < any > ((resolve, reject) => {
          this.kudoService.isTopupPhoneAvailable(this.topupForm.controls.phone.value)
              .subscribe(
                  (response: any[]) => resolve(null),
                  (error) => resolve({
                      'phoneNotAvailable': true
                  })
              );
      });
      return promise;
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

}

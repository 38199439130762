import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';

import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { GeneralService } from '../../core/services/general.service';

import { Meta, Title } from '@angular/platform-browser';

import { SeoService } from '../../core/services/seo.service';

import SHA1 from 'sha1';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

	paymentForm: FormGroup;

	qurbans: any;

	name: string;
	price: number;
	qty: number = 1;

	currentDateTimeDoku;
	dateConvertedDoku;

	paymentDoku: any = {
	  basket: '',
	  mallid: '',
	  chainmerchant: '',
	  currency: '',
	  purchasecurrency: '',
	  paymentchannel: '47',
	  amount: '',
	  purchaseamount: '',
	  transidmerchant: '',
	  words: '',
	  requestdatetime: '',
	  sessionid: '',
	  email: '',
	  name: '',
	  mobilephone: ''
	};

  constructor(private route: ActivatedRoute,
  				private router: Router,
  				private spinner: NgxSpinnerService,
  				private generalService: GeneralService,
  				private location: Location,
  				private title: Title,
  				private seoService: SeoService) { }

  ngOnInit() {	
  	this.name = this.route.snapshot.queryParams['type_qurban'];

  	if (this.name == null) { 
  		this.router.navigate(['/pergibelanja/qurban']);
  	}
  	this.qurbans = [
  		{
  			'name': 'Sapi',
  			'price': 12975000,
  			'qty': 1
  		},
  		{
  			'name': 'Kurban Standar',
  			'price': 1490000,
  			'qty': 7
  		},
  		{
  			'name': 'Kurban Medium',
  			'price': 1690000,
  			'qty': 7
  		},
  		{
  			'name': 'Kurban Premium',
  			'price': 1890000,
  			'qty': 7
  		},
  	];

  	this.selectQurban(this.name);

  	this.paymentForm = new FormGroup({
  		'infos': new FormGroup({
  			'type': new FormControl(null, Validators.required),
  			'total_people': new FormControl(null, Validators.required),
  			'price': new FormControl(this.price, Validators.required),
  			'list_people': new FormControl(null, Validators.required)
  		}),
  		'donaturs': new FormGroup({
  			'greeting': new FormControl(null, Validators.required),
  			'name': new FormControl(null, [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]),
  			'email': new FormControl(null, Validators.required),
  			'phone': new FormControl(null, Validators.required)
  		}),
        'transactions': new FormGroup({
            'payment_channel': new FormControl(null, Validators.required),
        })
    });

    // Seo
    this.title.setTitle('Qurban - Payment');
    this.seoService.generateTags({
      title: 'Qurban - Payment',
      description: 'Qurban - Payment',
      keywords: 'Qurban - Payment'
    });
  }

  selectQurban(event: any) {
  	this.name = event;
  	if (event == 'Sapi') { 
  		this.price = 12975000;
  	} else if (event == 'Kurban Standar') {
  		this.price = 1490000;
  	} else if (event == 'Kurban Medium') {
  		this.price = 1690000;
  	} else if (event == 'Kurban Premium') {
  		this.price = 1890000;
  	}
  }

  selectQty(event: number) {
  	this.qty = event;
  	console.log(event)
  }

  onSubmit() {
  	console.log('invalid')
  	console.log(this.paymentForm)
    if (this.paymentForm.valid) {
    	console.log('valid')
    	console.log(this.paymentForm)

    	this.currentDateTimeDoku = new Date();
    	this.dateConvertedDoku = this.convertToString(this.currentDateTimeDoku);

    	const wordsDoku = SHA1((this.price * this.qty) + '.005625' + 'YTeoFQxv9112' + this.dateConvertedDoku);
	  	this.paymentDoku.basket = this.name + ',' + (this.price * this.qty) + '.00' + ',' + '1' + ',' + (this.price * this.qty) + '.00';
	    this.paymentDoku.mallid = '5625';
	    this.paymentDoku.chainmerchant = 'NA';
	    this.paymentDoku.currency = '360';
	    this.paymentDoku.purchasecurrency = '360';
	    this.paymentDoku.paymentchannel = this.paymentForm.controls.transactions.value.payment_channel;
	    this.paymentDoku.amount = (this.price * this.qty) + '.00';
	    this.paymentDoku.purchaseamount = (this.price * this.qty) + '.00';
	    this.paymentDoku.transidmerchant = this.dateConvertedDoku;
	    this.paymentDoku.words = wordsDoku;
	    this.paymentDoku.requestdatetime = this.dateConvertedDoku;
	    this.paymentDoku.sessionid = this.dateConvertedDoku;
	    this.paymentDoku.name = this.paymentForm.controls.donaturs.value.name;
	    this.paymentDoku.email = this.paymentForm.controls.donaturs.value.email;
	    this.paymentDoku.mobilephone = this.paymentForm.controls.donaturs.value.phone;
    	
        this.payment();
    } else {
        this.validateAllFormFields(this.paymentForm);
    }
  }

  payment() {
      this.spinner.show();
      this.generalService.qurban(this.paymentForm.controls, this.paymentDoku)
          .subscribe(
              data => {
                this.paymentForm.reset();
                this.spinner.hide();
                console.log('sukses')
                // this.router.navigate(['/pergibelanja/qurban']);
                // Swal.fire(
                //     'Berhasil',
                //     'Sukses',
                //     'success'
                // ).then(result => {
                //   if (result.value) {
                //     this.router.navigate(['/pergibelanja/qurban']);
                //   } else {
                //     this.router.navigate(['/pergibelanja/qurban']);
                //   }
                // });
              },
              err => {
              	console.log('hey')
              	console.log(err)
              	// let form: HTMLFormElement = <HTMLFormElement>document.getElementById('MerchatPaymentPage');
                
                // form.submit();
              	// this.paymentForm.reset();
                this.spinner.hide();
              	// this.router.navigate(['/pergibelanja/qurban']);
              }
          );
      
  }

  onBack() {
    this.location.back();
  }

  validateAllFormFields(formGroup: FormGroup) {
      Object.keys(formGroup.controls).forEach(field => {
          const control = formGroup.get(field);
          if (control instanceof FormControl) {
              control.markAsTouched({
                  onlySelf: true
              });
          } else if (control instanceof FormGroup) {
              this.validateAllFormFields(control);
          }
      });
  }

  // function
  convertToString(date) {
    const yyyy = date.getFullYear().toString();
    const MM = this.pad(date.getMonth() + 1, 2);
    const dd = this.pad(date.getDate(), 2);
    const hh = this.pad(date.getHours(), 2);
    const mm = this.pad(date.getMinutes(), 2);
    const ss = this.pad(date.getSeconds(), 2);
    return yyyy + MM + dd + hh + mm + ss;
  }

  pad(number, length) {
    let str = '' + number;
    while (str.length < length) {
      str = '0' + str;
    }
    return str;
  }

}

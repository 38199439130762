import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';
import { CoreAuthService } from '../../core/services/auth.service';
import { EmasDigiService } from '../../core/services/emas-digi.service';
import { KudoService } from '../../core/services/kudo.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-agen-daftar-kilau-umroh',
  templateUrl: './agen-daftar-kilau-umroh.component.html',
  styleUrls: ['./agen-daftar-kilau-umroh.component.scss']
})
export class AgenDaftarKilauUmrohComponent implements OnInit, OnDestroy {

  private _destroyed$ = new Subject();

  registerForm: FormGroup;

  termsAndConditions: any;

  // upload ktp
  ktpSelectedFile = null;
  ktpSelfieSelectedFile = null;
  ktpImageURL: any;
  ktpSelfieImageURL: any;

  isButtonDisable: boolean = true;

  errorStatus = false;
  errorText = 'Data tidak valid';

  constructor(private router: Router,
              private sanitizer: DomSanitizer,
              private spinner: NgxSpinnerService,
              public authService: CoreAuthService,
              public emasDigiService: EmasDigiService,
              public kudoService: KudoService) { }

  ngOnInit() {

    this.registerForm = new FormGroup({
        'name': new FormControl(null, [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]),
        'phone': new FormControl(null, [Validators.required, Validators.pattern('[0-9]*')], this.checkPhoneInput.bind(this)),
        'identityCardNumber': new FormControl(null, [Validators.required, Validators.minLength(16), Validators.pattern('[0-9]*')], this.checkKtpInput.bind(this))
    });

  }

  initTermsData() {
    this.spinner.show();
    this.emasDigiService.getTermsAndConditions()
    .pipe(takeUntil(this._destroyed$))
    .subscribe(
      data => {
        this.termsAndConditions = this.sanitizer.bypassSecurityTrustHtml(data['data']);
        this.spinner.hide();
      },
      err => {  this.spinner.hide(); }
    );
  }



  onRegisterKilauUmroh() {
    if (this.registerForm.valid) {
        this.spinner.show();

        const formData1 = new FormData();
        formData1.append('name', this.registerForm.controls.name.value);
        formData1.append('phone', this.registerForm.controls.phone.value);
        formData1.append('identity_card_number', this.registerForm.controls.identityCardNumber.value);
        formData1.append('identity_card_photo', this.ktpSelectedFile);
        formData1.append('identity_card_selfie', this.ktpSelfieSelectedFile);
        formData1.append('kudo', 'true');

        this.emasDigiService.postRegisterUserKudo(formData1)
            .subscribe(
                data => {
                  const formData2 = new FormData();
                  formData2.append('name', this.registerForm.controls.name.value);
                  formData2.append('phone', this.registerForm.controls.phone.value);
                  formData2.append('email', data['data']['email']);
                  formData2.append('identity_card_number', this.registerForm.controls.identityCardNumber.value);
                  formData2.append('identity_card_photo', this.ktpSelectedFile);
                  formData2.append('identity_card_selfie', this.ktpSelfieSelectedFile);
                  formData2.append('account_id', data['data']['account_id']);
                  this.kudoService.registerKilauUmroh(formData2)
                      .subscribe(
                          dataPu => {
                            this.spinner.hide();
                            const userPhone = dataPu['data']['phone'];
                            Swal.fire(
                                'Berhasil',
                                'Pendaftaran Kilau Umroh dengan nomor ' + userPhone + ' Berhasil!',
                                'success'
                            ).then(result => {
                              if (result.value) {
                                this.router.navigate(['/kudo/dashboard']);
                              } else {
                                this.router.navigate(['/kudo/dashboard']);
                              }
                            });
                          },
                          err => {
                              this.spinner.hide();
                          }
                      );
                      this.errorStatus = false;
                },
                err => {
                    this.errorStatus = true;
                    this.spinner.hide();
                }
            );


    } else {
        this.validateAllFormFields(this.registerForm);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
      Object.keys(formGroup.controls).forEach(field => {
          const control = formGroup.get(field);
          if (control instanceof FormControl) {
              control.markAsTouched({
                  onlySelf: true
              });
          } else if (control instanceof FormGroup) {
              this.validateAllFormFields(control);
          }
      });
  }

  checkPhoneInput(control: FormControl): Promise < any > | Observable < any > {
      const promise = new Promise < any > ((resolve, reject) => {
          this.kudoService.isCustomerPhoneAvailable(this.registerForm.controls.phone.value)
              .subscribe(
                  (response: any[]) => resolve(null),
                  (error) => resolve({
                      'phoneNotAvailable': true
                  })
              );

      });
      return promise;
  }

  checkKtpInput(control: FormControl): Promise < any > | Observable < any > {
      const promise = new Promise < any > ((resolve, reject) => {
        console.log(this.registerForm);
        this.kudoService.isKtpAvailable(this.registerForm.controls.identityCardNumber.value)
              .subscribe(
                  (response: any[]) => resolve(null),
                  (error) => resolve({
                      'ktpNotAvailable': true
                  })
              );

      });
      return promise;
  }

  onKtpSelected(event) {
    this.ktpSelectedFile = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(this.ktpSelectedFile); 
    reader.onload = (_event) => { 
      this.ktpImageURL = reader.result; 
    }
  }

  onKtpSelfieSelected(event) {
    this.ktpSelfieSelectedFile = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(this.ktpSelfieSelectedFile); 
    reader.onload = (_event) => { 
      this.ktpSelfieImageURL = reader.result; 
    }
  }

  onAgree(event) {
    if (event.target.checked) {
      this.isButtonDisable = false;
    } else {
      this.isButtonDisable = true;
    }
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

}

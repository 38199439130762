import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { NgxSpinnerService } from 'ngx-spinner';
import { CoreAuthService } from '../../core/services/auth.service';
import { KudoService } from '../../core/services/kudo.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-agen-register',
  templateUrl: './agen-register.component.html',
  styleUrls: ['./agen-register.component.scss']
})
export class AgenRegisterComponent implements OnInit {

  registerForm: FormGroup;

  PHONE_REGEXP = /^(^\+62\s?|^0)(\d{3,4}?){2}\d{3,4}$/g;

  constructor(private router: Router,
              private spinner: NgxSpinnerService,
              public authService: CoreAuthService,
              public kudoService: KudoService) { }

  ngOnInit() {

    if (this.authService.isAuthenticatedCheckAgent['value']) {
      this.router.navigate(['/kudo/dashboard']);
    } else {
      // alert('gak login');
    }

    this.registerForm = new FormGroup({
        'agentId': new FormControl(null, Validators.required),
        'name': new FormControl(null, [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]),
        'phone': new FormControl(null, [Validators.required], this.checkPhoneInput.bind(this)),
        'credentials': new FormGroup({
            'password': new FormControl(null, [Validators.required, Validators.minLength(6)]),
            'confirmPassword': new FormControl(null, [Validators.required, Validators.minLength(6)])
        }, this.isPasswordMatch)
    });

  }

  onRegister() {
      if (this.registerForm.valid) {
          this.register();
      } else {
          this.validateAllFormFields(this.registerForm);
      }
  }

  register() {
      this.spinner.show();
      this.kudoService.register(this.registerForm.controls)
          .subscribe(
              data => {
                this.registerForm.reset();
                this.spinner.hide();
                Swal.fire(
                    'Berhasil',
                    'Pendaftaran Agen Berhasil, silahkan login!',
                    'success'
                ).then(result => {
                  if (result.value) {
                    this.router.navigate(['/kudo']);
                  } else {
                    this.router.navigate(['/kudo']);
                  }
                });
              },
              err => {
                  this.spinner.hide();
              }
          );
      
  }

  validateAllFormFields(formGroup: FormGroup) {
      Object.keys(formGroup.controls).forEach(field => {
          const control = formGroup.get(field);
          if (control instanceof FormControl) {
              control.markAsTouched({
                  onlySelf: true
              });
          } else if (control instanceof FormGroup) {
              this.validateAllFormFields(control);
          }
      });
  }

  checkPhonePattern(control: FormControl): {
      [s: string]: boolean
  } {
      const PHONE_REGEXP = /^(^\+62\s?|^0)(\d{3,4}?){2}\d{3,4}$/g;

      if (this.PHONE_REGEXP.test(control.value)) {
          return null;
      } else if (control.value == null) {
          return null;
      } else {
          return {
              'phoneIsNotValid': true
          };
      }
  }

  checkPhoneInput(control: FormControl): Promise < any > | Observable < any > {
      const promise = new Promise < any > ((resolve, reject) => {
          this.kudoService.isPhoneAvailable(this.registerForm.controls.phone.value)
              .subscribe(
                  (response: any[]) => resolve(null),
                  (error) => resolve({
                      'phoneNotAvailable': true
                  })
              );

      });
      return promise;
  }

  isPasswordMatch(form: FormGroup) {
      if (form.get('password').value !== form.get('confirmPassword').value) {
          return {
              'passwordNotMatch': true
          };
      }
      return null;
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { Meta, Title } from '@angular/platform-browser';

import { LOCAL_STORAGE } from '@ng-toolkit/universal';

import { Subject, combineLatest } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import { CoreAuthService } from '../core/services/auth.service';
import { GeneralService } from '../core/services/general.service';

import { SeoService } from '../core/services/seo.service';
import { MetaService } from '../core/services/meta.service';
import { HomeService } from '../core/services/home.service';

import { NgxSpinnerService } from 'ngx-spinner';

declare var jQuery:any;

declare let ga: Function;

@Component({
  selector: 'app-pergi-belanja',
  templateUrl: './pergi-belanja.component.html',
  styleUrls: ['./pergi-belanja.component.scss']
})
export class PergiBelanjaComponent implements OnInit {

  pageSize: number;
  currentPage: number = 1;
  productTotal: number;

  products: any;
  totalItem: any = 0;
  sortBy: any = 'default';
  category: any = '100';
  keyword: string = '';

  page: number = 1;

  array = [];
  sum = 100;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = '';
  modalOpen = false;

  isLoading: boolean = false;
  customIndex = 1;

  is_popup: any = 'false';

  search: any;
  searchBrand: any;
  checkSearch: boolean = false;

  banners: any;
  popups: any;
  brands: any;
  categoryId: any;
  categories: any;

  syaratKetentuan: any;
  subBanner: any;

  testimonials: Array<any>;

  private _destroyed$ = new Subject();

  constructor(
    @Inject(LOCAL_STORAGE) private localStorage: any,
    private sanitizer: DomSanitizer,
    private title: Title,
    private router: Router,
    public authService: CoreAuthService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private generalService: GeneralService,
    private homeService: HomeService,
    private seoService: SeoService,
    private metaService: MetaService) { }

  ngOnInit() {
    this.authService.isAuthenticated();

    this.metaService.getSyaratKetentuanPergiBelanja()
        .pipe(takeUntil(this._destroyed$))
        .subscribe(
            data => {
                // console.log(data);
                this.syaratKetentuan = this.sanitizer.bypassSecurityTrustHtml(data['data']['content']);

                // SEO
                // this.title.setTitle(data['data']['seo_title']);
                // this.seoService.generateTags({
                //   title: data['data']['seo_title'],
                //   description: data['data']['seo_metadesc'],
                //   keywords: data['data']['seo_metakeyword']
                // });

                this.spinner.hide();
            },
            () => { this.spinner.hide(); }
        );


    let currentPage = this.route.snapshot.queryParams['page'];
    if (currentPage === undefined) {
      currentPage = 1;
      this.customIndex = 1;
    } else {
      this.customIndex = parseInt(currentPage);
    }

    this.spinner.show();
      this.generalService.getProducts('default', currentPage, 100, this.keyword).subscribe(
      data => {
        // console.log('data')
        // console.log(data)
        this.products = data['data'];

        this.productTotal = data['params']['totalItem'];
        this.pageSize = 6;
        this.currentPage = 1;

        // console.log('products');
        // console.log(this.products);
        this.spinner.hide();
      },
      err => {
        // console.log('eeeror');
        console.log(err);
        this.spinner.hide();
      }
    );



    // Banner
    this.generalService.getBannerPergibelanja('pergibelanja').pipe(takeUntil(this._destroyed$)).subscribe(
      data => {
        this.banners = data['data'];
        console.log(this.banners)
        this.spinner.hide();
      },
      err => {
        console.log(err);
        this.spinner.hide();
      }
    );

    this.homeService.getTestimonials('pergibelanja').pipe(takeUntil(this._destroyed$)).subscribe(
      data => {
        this.testimonials = data['data'];
        console.log(this.testimonials)
          if (this.testimonials[0] == undefined) {
            this.testimonials = null;
          }
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
      }
    );

    // Banner
    this.generalService.getSubBannerPergibelanja().pipe(takeUntil(this._destroyed$)).subscribe(
      data => {
        this.subBanner = data['data']['image'];
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
      }
    );

    // brand
    // this.brand();

    // Category
    this.generalService.getCategoryPergibelanja().pipe(takeUntil(this._destroyed$)).subscribe(
      data => {
        this.categories = data['data'];
        // this.brands = data['data'];
        console.log(this.categories)
        this.spinner.hide();
      },
      err => {
        console.log(err);
        this.spinner.hide();
      }
    );

    this.generalService.getPopupPergibelanja().pipe(takeUntil(this._destroyed$)).subscribe(
      data => {
        this.popups = data['data'];
        // this.brands = data['data'];
        // console.log(this.popups)
        this.is_popup = this.localStorage.getItem('is_popup') == null || this.localStorage.getItem('is_popup') == 'false' ? 'false' : 'true';
        if (this.is_popup == 'false' && this.popups) {
          this.is_popup = this.localStorage.setItem('is_popup', false);
          jQuery('#staticBackdrop').modal('show');
        }

        this.spinner.hide();
      },
      err => {
        console.log(err);
        this.spinner.hide();
      }
    );

    this.metaService.getMetaPergiBelanja()
      .pipe(takeUntil(this._destroyed$))
        .subscribe(
          data => {
            // console.log(data);

            // SEO
            this.title.setTitle(data['data']['seo_title']);
            this.seoService.generateTags({
              title: data['data']['seo_title'],
              description: data['data']['seo_metadesc'],
              keywords: data['data']['seo_metakeyword']
            });

            this.spinner.hide();
        },
        () => { this.spinner.hide(); }
    );

    ga('send', 'event', {
      eventCategory: 'PergiBelanja',
      eventAction: 'PergiBelanja Viewed',
      // eventLabel: data['name']
    });
  }

  popup(){
    this.localStorage.setItem('is_popup', true);
    jQuery('#staticBackdrop').modal('hide');
  }

  createRange(number){
    var items: number[] = [];
    for(var i = 0; i <= number; i++){
       items.push(i);
    }
    return items;
  }

  searchFilter(e)
  {
    if (e == '' || e == null) {
      this.checkSearch = false;
      this.search = '';
    } else {
      this.generalService.searchBrandPergibelanja(e).pipe(takeUntil(this._destroyed$)).subscribe(
        data => {
          this.checkSearch = true;
          this.searchBrand = data['data'];
          // console.log(data['data'])
          this.spinner.hide();
        },
        err => {
          console.log(err);
          this.spinner.hide();
        }
      );
    }

  }

  brand() {
    this.categoryId = 0;
    this.spinner.show();
    this.generalService.getBrandPergibelanja().pipe(takeUntil(this._destroyed$)).subscribe(
      data => {
        this.brands = data['data'];
        // console.log(this.brands[10])
        this.spinner.hide();
      },
      err => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }

  filter(id) {
    this.categoryId = id;
    // Category detail
    this.spinner.show();
    this.generalService.getCategoryDetailPergibelanja(id).pipe(takeUntil(this._destroyed$)).subscribe(
      data => {
        this.brands = data['data']['brands'];
        // console.log(this.categories)
        this.spinner.hide();
      },
      err => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }

  clipboard(string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = string;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    var copyText = document.getElementById("clipboard");
    copyText.title = 'Copied';
  }

  onSortBy(value) {
    console.log('onSortBy')
    console.log(value);
    this.sortBy = value;
      this.spinner.show();
        this.generalService.getProducts(value, 1, this.category, this.keyword).subscribe(
        data => {
          this.products = data['data'];
          this.productTotal = data['params']['totalItem'];
          this.spinner.hide();
        },
        err => {
          console.log('error');
          console.log(err);
          this.spinner.hide();
        }
      );
   }

   pageChanged(event) {
     console.log('event ')
     console.log(event)
       this.spinner.show();

         this.generalService.getProducts(this.sortBy, event, this.category, this.keyword).subscribe(
         data => {
           this.products = data['data'];

           // console.log('daaata');
           // console.log(data);

           this.productTotal = data['params']['totalItem'];
           this.pageSize = 6;
           this.currentPage = event;
           this.router.navigate(['/jadwal-umroh'], { queryParams: { page: event }, queryParamsHandling: 'merge' });

           this.spinner.hide();
         },
         err => {
           console.log('error');
           console.log(err);
           this.spinner.hide();
         }
       );
   }

   onSearchProducts() {
       this.spinner.show();
       this.sortBy = 'default';

       this.generalService.getProducts(this.sortBy, 1, this.category, this.keyword).subscribe(
       data => {
         this.products = data['data'];

         this.productTotal = data['params']['totalItem'];
         this.pageSize = 6;
         this.currentPage = 1;
         this.router.navigate(['/jadwal-umroh'], { queryParams: {
           keyword: this.keyword,
           category: this.category
         }, queryParamsHandling: 'merge' });

         this.spinner.hide();
       },
       err => {
         console.log('error');
         console.log(err);
         this.spinner.hide();
       }
       );

   }

   open(url, name_brand) {
    ga('send', 'event', {
      eventCategory: 'PergiBelanja',
      eventAction: 'PergiBelanja Detail',
      eventLabel: name_brand
    });
     window.open(url, '_blank');
   }


  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  copyInputMessage(inputElement){
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  onClick(e, link) {

      if (link == null) {
          e.preventDefault();
      }

  }

}

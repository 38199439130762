import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { LOCAL_STORAGE } from '@ng-toolkit/universal';

import { map } from 'rxjs/operators';

import { Config } from '../config';

@Injectable({
  providedIn: 'root'
})
export class KilauUmrohService {

  constructor(@Inject(LOCAL_STORAGE) private localStorage: any,
              private http: HttpClient,
              private config: Config) { }

  getTnCKudo() {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };
    return this.http
      .get<any[]>(this.config.url + 'page/tnc-kudo', httpOptions)
      .pipe(map(data => data));
  }

  /*
  |--------------------------------------------------------------------------
  | KILAU UMROH
  |--------------------------------------------------------------------------
  */


  /*
  |--------------------------------------------------------------------------
  | ACCOUNT
  |--------------------------------------------------------------------------
  */

  register(formData) {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };
    return this.http
        .post<any[]>(this.config.emasDigiUrl + 'account', formData, httpOptions)
        .pipe(map(data => data));
  }

  getAccountBalance() {
      const token = this.localStorage.getItem('token');
      const httpOptions = {
          headers: new HttpHeaders({
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + token
          })
      };
      return this.http
        .get<any[]>(this.config.emasDigiUrl + 'account/balance', httpOptions)
        .pipe(map(data => data));
  }



  /*
  |--------------------------------------------------------------------------
  | GOLD
  |--------------------------------------------------------------------------
  */

  calculateGold(action: string, type, amount) {
      let params = new HttpParams();
      params = params.append('action', action);
      params = params.append('type', type);
      params = params.append('amount', amount);
      const token = this.localStorage.getItem('token');
      const httpOptions = {
          params: params,
          headers: new HttpHeaders({
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + token
          })
      };
      return this.http
        .get<any[]>(this.config.emasDigiUrl + 'gold/calculate', httpOptions)
        .pipe(map(data => data));
  }

  getCurrentGoldPrices() {
      const token = this.localStorage.getItem('token');
      const httpOptions = {
          headers: new HttpHeaders({
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + token
          })
      };
      return this.http
        .get<any[]>(this.config.emasDigiUrl + 'gold/price', httpOptions)
        .pipe(map(data => data));
  }

  topup(goldAmount, buyPrice, total, transferCode) {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };
    return this.http
      .post<any[]>(this.config.emasDigiUrl + 'gold/buy', {
          gold_amount: goldAmount,
          unit_price: buyPrice,
          total: total,
          transfer_code: transferCode
      }, httpOptions)
      .pipe(map(data => data));
  }



  /*
  |--------------------------------------------------------------------------
  | GENERIC
  |--------------------------------------------------------------------------
  */

  getTnc() {
      const token = this.localStorage.getItem('token');
      const httpOptions = {
          headers: new HttpHeaders({
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + token
          })
      };
      return this.http
        .get<any[]>(this.config.emasDigiUrl + 'termsandconditions', httpOptions)
        .pipe(map(data => data));
  }



  /*
  |--------------------------------------------------------------------------
  | AVAILABLE CHECK
  |--------------------------------------------------------------------------
  */

  isPluangMember() {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };
    return this.http
      .get<any[]>(this.config.emasDigiUrl + 'ismember', httpOptions)
      .pipe(map(data => { 
        return true;
      }));
  }

  isMember() {
      const token = this.localStorage.getItem('token');
      const httpOptions = {
          headers: new HttpHeaders({
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + token
          })
      };
      return this.http
        .get<any[]>(this.config.emasDigiUrl + 'ismember', httpOptions)
        .pipe(map(data => data));
  }

  isPhoneAvailable(phone) {

      let params = new HttpParams();
      params = params.append('phone', phone);

      const token = this.localStorage.getItem('token');
      const httpOptions = {
          params: params,
          headers: new HttpHeaders({
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + token
          })
      };
     return this.http
      .get<any[]>(this.config.emasDigiUrl + 'isphoneavailable', httpOptions)
      .pipe(map(data => data));
  }

  isKtpAvailable(identityCardNumber) {
      let params = new HttpParams();
      params = params.append('identity_card_number', identityCardNumber);
      const token = this.localStorage.getItem('token');
      const httpOptions = {
          params: params,
          headers: new HttpHeaders({
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + token
          })
      };
     return this.http
      .get<any[]>(this.config.emasDigiUrl + 'isktpavailable', httpOptions)
      .pipe(map(data => data));
  }


  getSetting(key: string) {
      const token = this.localStorage.getItem('token');
      const httpOptions = {
          headers: new HttpHeaders({
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + token
          })
      };
      return this.http
        .get<any[]>(this.config.emasDigiUrl + 'setting/' + key, httpOptions)
        .pipe(map(data => data));
  }



  /*
  |--------------------------------------------------------------------------
  | ORDER
  |--------------------------------------------------------------------------
  */

  getOrderList() {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };
    return this.http
      .get<any[]>(this.config.emasDigiUrl + 'order', httpOptions)
      .pipe(map(data => data));
  }

  getOrder(orderCode) {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };
    return this.http
      .get<any[]>(this.config.emasDigiUrl + 'order/' + orderCode, httpOptions)
      .pipe(map(data => data));
  }

  getLogs(type: string, page: string) {
      let params = new HttpParams();
      params = params.append('type', type);
      params = params.append('limit', '10');
    const token = this.localStorage.getItem('token');
    const httpOptions = {
        params: params,
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };
    return this.http
      .get<any[]>(this.config.emasDigiUrl + 'log' + page, httpOptions)
      .pipe(map(data => data));
  }

  getTransactions(type: string, page: string) {
      let params = new HttpParams();
      params = params.append('type', type);
      params = params.append('limit', '10');
    const token = this.localStorage.getItem('token');
    const httpOptions = {
        params: params,
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };
    return this.http
      .get<any[]>(this.config.emasDigiUrl + 'transaction' + page, httpOptions)
      .pipe(map(data => data));
  }



  /*
  |--------------------------------------------------------------------------
  | CONFIRMATION
  |--------------------------------------------------------------------------
  */
  paymentConfirmation(formData, orderCode) {
    const token = this.localStorage.getItem('token');
    const httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    };
    return this.http
      .post<any[]>(this.config.emasDigiUrl + 'confirmation/' + orderCode, formData, httpOptions)
      .pipe(map(data => data));
  }

}

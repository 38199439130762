import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';

import { CoreAuthService } from '../core/services/auth.service';
import { GeneralService } from '../core/services/general.service';

import Swal from 'sweetalert2';

declare var jQuery:any;

@Component({
  selector: 'app-umroh-mandiri',
  templateUrl: './umroh-mandiri.component.html',
  styleUrls: ['./umroh-mandiri.component.scss']
})
export class UmrohMandiriComponent implements OnInit {
	private _destroyed$ = new Subject();

	customUrmohForm: FormGroup;

	nowDate: Date;
  departureDate: any;

  departMonth: any = '01';
  departYear: any = '2021';

	departureDateFormat: any;
	duration: any = '';
	departureCity: any = '';
	departureCities: any;
	pilgrim: any = '';
	airline: Array<any>;
  airlineIndex: any = '';
  dataOrder = {
    name: '',
    phone: ''
  }

	hotel = {
		makkah : {
			rating: '',
			name: '',
			room: '',
			night: '',
		},
		madinah : {
			rating: '',
			name: '',
			room: '',
			night: '',
		}
	};

	minDate: any;

	otherServices: any[];

  result: any;

	airlines: any;
	hotelsMak: any;
	hotelsMad: any;

  statusRequest: boolean;
  departureDuration: number = 1;

  constructor(private spinner: NgxSpinnerService,
  			private generalService: GeneralService,
  			public authService: CoreAuthService,
        private router: Router) { }

  ngOnInit() {
  	this.spinner.show();
  	this.duration = '';
  	this.departureCity = '';
  	this.pilgrim = '';
  	this.airline = null;

  	this.hotel = {
  		makkah : {
  			rating: '',
  			name: '',
  			room: '',
  			night: '',
  		},
  		madinah : {
  			rating: '',
  			name: '',
  			room: '',
  			night: '',
  		}
  	};

  	combineLatest(
      this.generalService.getAllAirline('', '', 1),
      this.generalService.getAllHotel('')
    ).pipe(
      map(([airline, hotel]) => ({ airline, hotel }) ),
      takeUntil(this._destroyed$))
    .subscribe(
      data => {
      	// this.airlines = data['airline']['data'];

        this.hotelsMak = data['hotel']['makkah'];
        this.hotelsMad = data['hotel']['madinah'];

        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
      }
    );


    this.generalService.getCitiesDepartureFrom()
      .subscribe(
          data => {
            console.log('success: cities departure from')
            console.log(data)
            this.departureCities = data['data'];
            this.spinner.hide();
          },
          err => {
              console.log('error: cities departure from')
            this.spinner.hide();
          }
      );

    this.generalService.getStatusRequest()
      .subscribe(
          data => {
              console.log('success: status request')
            this.statusRequest = data['checkStatus'];
            this.spinner.hide();
          },
          err => {
              console.log('error status request')
              console.log(err)
            this.spinner.hide();
          }
     );


  	this.departureDate = new Date();

  	this.nowDate = this.departureDate;
  	this.minDate = {
		year: this.departureDate.getFullYear(),
		month: this.departureDate.getMonth() + 1,
		day: this.departureDate.getDate()
	};

  	this.otherServices = [
  		{
  			id: 1,
  			name: 'Suntik Meningitis',
  			selected: false
  		},
  		{
  			id: 2,
  			name: 'Tour Makkah',
  			selected: false
  		},
  		{
  			id: 3,
  			name: 'Modem Internet WIFI',
  			selected: false
  		},
  		{
  			id: 4,
  			name: 'Rapid Test Covid-19',
  			selected: false
  		},
  		{
  			id: 5,
  			name: 'Tour Turki',
  			selected: false
  		},
  		{
  			id: 6,
  			name: 'Oleh-oleh Zam-zam, kurma',
  			selected: false
  		}
  	];
  }

  onCheckRequest(){
    Swal.fire(
      'Info!',
      'mohon ditunggu proses pengajuan sebelumnya sedang diproses!.',
      'info'
    );
  }

  onChangeDepartureDate(e ) {
      let departureDate = e.year + '-' + e.month + '-' + e.day;
      this.spinner.show();
      this.generalService.getAllAirline(departureDate, this.departureCity, this.departureDuration)
        .pipe(takeUntil(this._destroyed$))
        .subscribe(
          data => {
            this.airlines = data['data'];
            this.spinner.hide();
          },
          err => {
            this.spinner.hide();
        }
      );
  }

  onChangeDepartureCity(departureCity){

    this.spinner.show();
    this.generalService.getAllAirline(this.departYear +'-'+ this.departMonth +'-01', departureCity, this.departureDuration)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(
        data => {
          this.airlines = data['data'];
          console.log(this.airlines)
          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
      }
    );
  }

  onChangeRatingMak(value){
  	this.generalService.getAllHotel(value)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(
        data => {
          // console.log(data)
          this.hotelsMak = data['makkah'];
          // this.hotelsMad = data['madinah'];
          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
      }
    );
  }

  onChangeRatingMad(value){
  	this.generalService.getAllHotel(value)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(
        data => {
          // console.log(data)
          this.hotelsMad = data['madinah'];
          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
      }
    );
  }

  onAirlineChange(value){
    // console.log(value['idproduct'])
    this.airline = value;
  }

  onClick() {
    this.spinner.show();
    // this.duration = this.airline.split(',')[2];
    this.hotel.makkah.rating = this.hotel.makkah.name.split(', ')[2];
    this.hotel.madinah.rating = this.hotel.madinah.name.split(', ')[2];

    this.airline = this.airlines[this.airlineIndex];

    var aDept = new Date(this.airline['flights'][0].time_dept);
    var aReturn = new Date(this.airline['flights'][this.airline['flights'].length - 1].time_dept);

    this.duration = Math.floor((aReturn.getTime() - aDept.getTime()) / 1000 / 60 / 60 / 24);

    var departDate = this.airlines[this.airlineIndex].flights[0].time_dept;

    this.generalService.previewCustomUmroh(this.dataOrder, departDate, this.duration, this.departureCity, this.pilgrim, this.airline, this.hotel, this.otherServices)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(
        data => {
          this.result = data['data'];
          jQuery('#myModal').modal('show');
          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
      }
    );


  }

  onSubmitCustomUmroh() {
    this.hotel.madinah.room = this.hotel.makkah.room;

    var departDate = this.airlines[this.airlineIndex].flights[0].time_dept;

  	this.generalService.requestCustomUmroh(this.dataOrder, departDate, this.duration, this.departureCity, this.pilgrim, this.airline, this.hotel, this.otherServices)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(
        data => {
          Swal.fire(
            '<div style="font-size: 21px;">Permintaan Umroh Mandiri kamu akan diproses</div>',
            'Detail Paket Umroh dan Harga Final akan kami infokan dalam waktu 2x24 jam.',
            // 'Harga Quad: <strong>Rp'+data['data']['grandPrice']['quad'].toLocaleString('ID')+',-</strong><br>Harga Triple: <strong>Rp'+data['data']['grandPrice']['triple'].toLocaleString('ID')+',-</strong><br>Harga Double: <strong>Rp'+data['data']['grandPrice']['double'].toLocaleString('ID')+',-</strong>',
            // 'success'
          ).then(res => {
            this.router.navigate(['/user/umroh-mandiri']);
          	this.ngOnInit();
          });
          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
          Swal.fire(
            'Info!',
            'Mohon ditunggu proses pengajuan sebelumnya sedang diproses!.',
            'info'
          );
      }
    );
  }

  onChangeDepartureDuration(departureDate, duration) {
         let umrohDepartureDate = departureDate.year + '-' + departureDate.month + '-' + departureDate.day;
         const date = new Date(umrohDepartureDate);
         date.setDate(date.getDate() + 3);
         console.log(this.formatDate(date))

         this.spinner.show();
         this.generalService.getAllAirline(this.formatDate(date), this.departureCity, this.departureDuration)
           .pipe(takeUntil(this._destroyed$))
           .subscribe(
             data => {
               this.airlines = data['data'];
               this.spinner.hide();
             },
             err => {
               this.spinner.hide();
           }
         );
  }

  // onSubmit() {
  //   if (this.customUrmohForm.valid) {
  //   	alert('valid')
  //   } else {
  //     this.validateAllFormFields(this.customUrmohForm);
  //   }
  // }


  formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;

      return [year, month, day].join('-');
  }

  /*
  |--------------------------------------------------------------------------
  | VALIDATION
  |--------------------------------------------------------------------------
  */

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({
          onlySelf: true
        });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }



}
